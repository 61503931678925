import React, { useContext } from "react";
import PropTypes from "prop-types";

const Abonostdlight = ({
	abono: {
		folio,
		modo_pago,
		factura,
		suma,
		cajero,
		nombre,
		fecha,
		hab,
		abonos,
		status,
	},
}) => {
	let diames = "";
	if (fecha !== "sin fechas") {
		const fechaS = fecha.split(" ");
		const llegadaF = fechaS[0].split("-");
		const meses = [
			"dud",
			"Enero",
			"Feb",
			"Mar",
			"Abril",
			"Mayo",
			"Jun",
			"Jul",
			"Ago",
			"Sep",
			"Oct",
			"Nov",
			"Dic",
		];
		diames = llegadaF[2] + "/" + meses[llegadaF[1].replace(/^0+/, "")];
	}
	let tieneabono = false;
	let totalabono = 0;
	let cxcx = 0;
	if (typeof abonos !== "undefined") {
		abonos.map((r) => {
			if (r.metodo_pago === "Reembolso") {
				tieneabono = true;
			} else {
				totalabono = totalabono + parseFloat(r.abono);
			}
			if (r.metodo_pago === "cuentas por cobrar") {
				cxcx = cxcx + parseFloat(r.abono);
			}
		});
	}

	/////////////////////////////////////
	// abonos
	const aborow = (abonos) => {
		let salida = "";
		if (typeof abonos !== "undefined" || Array.isArray(abonos)) {
			let tr = abonos.map((row, i) => {
				return (
					<tr key={i} className='abodet noprint'>
						<td>cuenta:</td>
						<td>{row.cuenta_id}</td>
						<td>{row.fecha.substring(5, 10)}</td>
						<td>{row.fecha.substring(10, 16)}</td>
						<td>{row.metodo_pago}</td>
						<td></td>
						<td>{row.metodo_pago === "Efectivo" && moneyForm(row.abono)}</td>
						<td>{row.metodo_pago === "Transferencia" && moneyForm(row.abono)}</td>
						<td>
							{(row.metodo_pago === "Tarjeta de Debito" ||
								row.metodo_pago === "Tarjeta de Credito") &&
								moneyForm(row.abono)}
						</td>
						<td>
							{row.metodo_pago === "cuentas por cobrar" && moneyForm(row.abono)}
						</td>
						<td>{row.metodo_pago === "Reembolso" && moneyForm(row.abono)}</td>
						<td></td>
						<td></td>
						<td>{row.cajero}</td>
					</tr>
				);
			});
			salida = tr;
		} else {
			salida = null;
		}
		return salida;
	};
	//////////////////////////////////
	// rembolsos
	const rembo = (abonos, suma) => {
		let rembosum = 0;
		let totalabonos = 0;
		if (typeof abonos !== "undefined") {
			abonos.map((row) => {
				if (row.metodo_pago === "Reembolso") {
					rembosum = parseFloat(rembosum) + parseFloat(row.abono);
				} else {
					totalabonos = parseFloat(totalabonos) + parseFloat(row.abono);
				}
			});
		}
		if (rembosum !== 0) {
			return <strong> ${Math.abs(rembosum).toFixed(2)}</strong>;
		}
	};
	////////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		let numero = parseFloat(raw);
		if (numero < 0) numero = Math.abs(numero);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	return (
		<>
			{aborow(abonos)}
			<tr
				className={factura !== "" ? "abonomain" : status == 1 ? "cancelada" : null}
			>
				<td>{factura}</td>
				<td>{folio}</td>
				<td>{fecha !== "sin fechas" ? diames : fecha}</td>
				<td>{hab}</td>
				<td>{nombre}</td>
				<td>{factura !== "" && moneyForm(suma)}</td>
				<td>{modo_pago === "Efectivo" && factura === "" && moneyForm(suma)}</td>
				<td>
					{modo_pago === "Transferencia" && factura === "" && moneyForm(suma)}
				</td>
				<td>
					{modo_pago == "Tarjeta de Credito" && factura === ""
						? moneyForm(suma)
						: null}
					{modo_pago == "Tarjeta de Debito" && factura === ""
						? moneyForm(suma)
						: null}
				</td>
				<td>
					{/*modo_pago === "cuentas por cobrar" && factura === "" && moneyForm(suma)*/}
					{modo_pago === "CXC" && factura !== "" && moneyForm(suma)}
				</td>
				<td>{factura !== "" && rembo(abonos, suma)}</td>
				<td>
					{factura !== "" &&
						modo_pago !== "CXC" &&
						modo_pago !== "cuentas por cobrar" &&
						!tieneabono &&
						moneyForm(suma - cxcx)}
					{tieneabono && moneyForm(totalabono)}
				</td>
				<td>{factura == "" && moneyForm(suma)}</td>
				<td>{cajero}</td>
			</tr>
		</>
	);
};

Abonostdlight.propTypes = {
	abono: PropTypes.object.isRequired,
};
export default Abonostdlight;
