import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import alertContext from "../../contexto/alerta/alertContext";

 const Busqueda = (props) =>{
    /////////////////////////////////
    //CONTEXTO
    const cafeteriaContext = useContext(CafeteriaContext);
    const {traerCheque, traerChequesFecha, cheque} = cafeteriaContext;
    const AlertContext = useContext(alertContext);
    const {setAlert} = AlertContext;

    const navigate = useNavigate();
    /////////////////////////////////
    //STATE
    const [id, setID] = useState("");
    const [busqueda,setBusqueda] = useState({type:"text", placeholder:"Cheque"})
    /////////////////////////////////
    //METODOS
    const onClickB = async (event) =>{
        let tz = new Date(Date.now()).getTimezoneOffset() * 60000;
        const hoy = new Date(Date.now()-tz).toISOString().slice(0,10);
        
        event.preventDefault();
        if(busqueda.type==="text"){
            traerCheque(id)
            if(cheque.id)
                navigate(`/rcargos/${id}`);
            else
                setAlert("No se encontró el cheque", "danger"); 
        }
        else{
            if(id===""){
                await traerChequesFecha(hoy);
            }
            else{
                await traerChequesFecha(id);
                props.setFecha(id)
            }
        }
        
    }

    const tipoBusqueda = (e) =>{
        if(e.target.id === "fecha"){
            setBusqueda({type:"date", placeholder:""})
            setID("");
        }
        else{
            setID("");
            setBusqueda({type:"text", placeholder:"Cheque"});
        }  
    }

    return(
    <>
    <div className="floatRight noprint">
        <div className="grid-1 all-center">
            <label className="floatRight">Busqueda por: 
                <label id="id" className="clickable btn-primary" onClick={tipoBusqueda}> Cheque </label>|
                <label id="fecha" className="clickable btn-primary" onClick={tipoBusqueda}> Fecha</label>
            </label>
            <input {...busqueda} onChange={(e)=>setID(e.target.value)}></input> 
        </div>
        <button className="floatRight btn-block btn-primary" onClick={onClickB}>Buscar</button>
        </div>
    </>
    )
}


export default Busqueda;