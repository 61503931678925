import React, { useState } from "react";
import Empresath from "../empresas/Empresath";
import Rsocial2 from "../facturacion/rsocial2.js";
const Empresas = () => {
	const [newEmpresa, setNewEmpresa] = useState(false);
	const toggleNewEmpresa = () => {
		newEmpresa ? setNewEmpresa(false) : setNewEmpresa(true);
	};
	return (
		<>
			<h1>
				Datos fiscales
				<>
					<span className='floatRight'>
						{newEmpresa ? (
							<i className='fas fa-minus-square' onClick={toggleNewEmpresa}></i>
						) : (
							<i className='fas fa-plus-square' onClick={toggleNewEmpresa}></i>
						)}
					</span>
				</>
			</h1>
			{newEmpresa ? <Rsocial2 /> : null}
			<Empresath />
		</>
	);
};

export default Empresas;
