import React from 'react';

import logoc from "../../assets/log-circulo.png";

const Cheque = React.forwardRef((props, ref) => {
    let cheque = props.cheque;
    const date = new Date();
    let fecha;

    var options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};
    let [hour, minutes] = [
		(date.getHours() < 10 ? "0" : "") + date.getHours(),
		(date.getMinutes() < 10 ? "0" : "") + date.getMinutes(),
	];
    const moneyForm = (raw) => {
		const numero = parseFloat(raw);
        let negativo = "";
        if(numero<0)
            negativo = "-";    
		if (!isNaN(numero)) {
			const res =negativo + "$" + Math.abs(numero.toFixed(2));
			return res;
		} else {
			return "Sin Cargos";
		}
	};
    const mPago = () => {
        let efe = 0;
        let tcred = 0;
        let tdeb = 0;

        if(typeof cheque.rcargos === "object"){
            cheque.rcargos.forEach((row)=>{
                if(row.clave===502)
                    efe = row.importe;
                if(row.clave === 503)
                    tcred = row.importe;
                if(row.clave === 504)
                    tdeb = row.importe;
            })
            if(efe>tcred && efe>tdeb)
                return "Efectivo";
            else if(tcred>efe && tcred>tdeb)
                return "T. Crédito";
            else if(tdeb>efe && tdeb>tcred)
                return "T. Débito";
        }

    }
    const convertirTimestamp = (timestamp) => {
        timestamp = timestamp.split(" ");
        const hora=timestamp[1];
        timestamp = timestamp[0].split("-");
        const meses = [
            "dud", "Enero", "Feb",
            "Mar", "Abril", "Mayo",
            "Jun", "Jul", "Ago",
            "Sep", "Oct", "Nov", "Dic",
        ];
        const fecha= timestamp[2] + "/" + meses[timestamp[1].replace(/^0+/,"")] 
        + "/" + timestamp[0].substring(2,4);
        return [hora, fecha];
    };
    if(Object.values(cheque).length>0)
        [,fecha] = convertirTimestamp(cheque.apertura)
    
    return (
        <>
            <div className='container'style = {{marginTop:"2em"}} ref={ref}>
            
                <header id='main'>
                    <nav className='container text-right overvi'>
                        <div className='branding'>
                            <img src={logoc} alt='Logo nav' />
                            <h3 className='mr-a'>
                                Hotel Real Plaza - El Mezzanine
                            </h3>
                        </div>
                    </nav>
                </header>
                <br />
                <h1 className='container'>
				    {date.toLocaleDateString("es-ES", options)}
				    <p className='floatRight'> {hour + ":" + minutes}hrs </p>
                    <p>Cheque número: {props.cheque.id}</p> 
                    	
                        
			    </h1>
                <div className='' >
                    <hr />
                    <h2 className='grid-4'>
                        <p>Status: {cheque.status}</p>
                        {cheque.mesa !== "" && cheque.mesa !== "0"?
                            <p>Mesa: {cheque.mesa}</p>
                        :<></>}
                        {cheque.roomservice?
                            <p>Room Service: Si</p>
                        :<></>}
                        {cheque.habitacion!==0&&cheque.habitacion!==""?
                            <p>Habitación: {cheque.habitacion}</p>
                            :<></>
                        }	
                        
                    </h2>
                    <hr />
                    <p className='floatRight'>Fecha: {fecha} </p>
                    <table className='table.tightable w-100'>
                        <thead>
                            <tr>
                                <th width="10%">Clave</th>
                                <th width="10%">Cant.</th>
                                <th width="50%">Descripcion</th>
                                <th width="10%">Costo</th>
                                <th width="10%">Importe</th>
                                <th>Hora</th>
                                <th>Cajero</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(cheque).length>0?typeof cheque.rcargos==="object"?cheque.rcargos.map((row,i)=>{
                                const [hora,] = convertirTimestamp(row.tiempo);
                                if(row.clave!==505&&row.clave!==501)
                                    return (<tr key={i} className={row.status===1?"":"text-danger text-line"}>
                                        <td>{row.clave}</td>
                                        <td>{row.cant}</td>
                                        <td>{row.descripcion}</td>
                                        <td>{moneyForm(row.precio)}</td>
                                        <td>{moneyForm(row.importe)}</td>
                                        <td>{hora}</td>
                                        <td>{row.cajero}</td>
                                        </tr>)
                                else
                                    return <tr key={i}></tr>
                                    }):<></>:<></>}                                    
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={4} className="text-right">Total:</th>
                                <th colSpan={1}>{moneyForm(cheque.importe)}</th>
                            </tr>
                        </tfoot>
                    </table>
                    <table className='table.tightable w-100'>
                        <thead>
                            <tr>
                                <th></th>
                                <th colSpan={2}>Pago Especial: {cheque.impresa===5?"Cargo a Habitación "+ props.hab:
                                    cheque.impresa===6?"Descuento de Empleado":cheque.impresa===7?"Cortesía":"Ninguno"}</th>
                                <th colSpan={1}>Descuento:</th>
                                <th colSpan={1}>{cheque.descuento}%</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th colSpan={2}>Método de Pago: {mPago()}</th>
                                <th colSpan={1}>Propina:</th>
                                <th colSpan={1}>{moneyForm(cheque.propina)}</th>
                                <th></th>
                            </tr>
                            <tr>
                                
                                <th colSpan={6} className="medium">Total: {moneyForm(parseFloat(cheque.importe)+(parseFloat(cheque.propina)))}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            
        </>
    )
    });

export default Cheque;