import React, { useContext, useState } from "react";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import { catalogo } from "./catalogo";
import { useParams } from "react-router-dom";

const NuevoRcargo = () => {
	////////////////////////////////
	// CONTEXTO
	const cafeteriaContext = useContext(CafeteriaContext);
	const { nuevoCargo, traerCheque, cheque, setLoading } = cafeteriaContext;

	////////////////////////////////
	//STATE
	const [concepto, setConcepto] = useState({
		clave: "",
		descripcion: "",
		precio: "",
		room: "",
	});
	const [cata, setCata] = useState([]);

	////////////////////////////////
	//CAMPO GENERICO
	const useField = ({ type, name }) => {
		const [value, setValue] = useState("");
		const onChange = (event) => {
			setValue(event.target.value);
		};
		return { type, value, name, onChange };
	};

	const [cantidad, setCantidad] = useState(1);

	const cantOnChange = (e) => {
		if (concepto.clave < 500) {
			setCantidad(e.target.value);
		} else {
			setCantidad(1);
		}
	};

	const precio = useField({ type: "number", name: "precio" });
	const cajero = useField({ type: "text", name: "cajero" });
	const descripcion = useField({ type: "text", name: "descripcion" });
	const { id } = useParams();

	////////////////////////////////
	//METODOS
	const submitCargo = async (event) => {
		event.preventDefault();
		setLoading(true);
		const nCargo = {
			cant: parseInt(cantidad),
			clave: concepto.clave,
			descripcion: concepto.descripcion,
			precio: parseFloat(precio.value),
			importe: parseFloat(cantidad * precio.value),
			cajero: cajero.value,
			cheque_id: parseInt(id),
		};
		await nuevoCargo(JSON.stringify(nCargo));
		await traerCheque(id);
		selector({target:{value:""}});
	};

	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	const cambioPrecios = async () => {
		let cat = [];
		if (cheque.roomservice) {
			catalogo.map((res, index) => {
				cat[index] = {
					clave: res.clave,
					descripcion: res.descripcion,
					precio: res.room,
				};
			});
			await setCata(cat);
		} else await setCata(catalogo);
	};

	//////////////////////////////////////
	//selector de clave
	const selector = async (event) => {
		cata.map((res, index) => {
			if (res.clave === event.target.value) {
				//if (index === parseInt(event.target.value)) {
				setConcepto(res);
				precio.onChange({ target: { value: res.precio } });
				if (res.clave > 500 && res.clave < 505) {
					precio.onChange({
						target: { value: parseFloat(cheque.importe).toFixed(2) },
					});
				}
			}
		});
		if (event.target.value === "") {
			setConcepto({ clave: "", descripcion: "", precio: "", room: "" });
			precio.onChange({ target: { value: "" } });
			setCantidad(1);
		}
	};
	if (cata.length === 0) cambioPrecios();

	return (
		<div className='noprint'>
			<form action='' onSubmit={submitCargo} className='grid-3'>
				<div>
					<label>Clave:</label>
					<br />
					<input id="menuinp" list='menu' type='text' onChange={selector} className='w-100' />
					<datalist id='menu'>
						{catalogo.map((option, index) => {
							return (
								<option key={index} value={option.clave}>
									{option.clave}-{option.descripcion}
								</option>
							);
						})}
					</datalist>
				</div>
				<div>
					<label>Descripcion:</label>
					<br />
					<input
						{...descripcion}
						value={concepto.descripcion}
						className='w-100'
						required
					/>
				</div>
				<div className='grid-3'>
					<div>
						<label>Precio:</label>
						<input
							{...precio}
							value={concepto.precio !== "" ? concepto.precio : precio.value}
							min={0}
							required
							step='.01'
						/>
						<label>Cajero:</label>
						<input {...cajero} required />
					</div>
					<div>
						<label>Cantidad:</label>
						<input
							min={1}
							type='number'
							value={cantidad}
							required
							step='1'
							onChange={cantOnChange}
						/>
						<div>
							<button
								type='submit'
								className='btn btn-dark btn-block'
								disabled={cheque.status === "abierta" ? false : true}
							>
								Agregar Cargo
							</button>
						</div>
					</div>
					<div>
						<label>Importe</label>
						<br />
						<label>{moneyForm(cantidad * precio.value)}</label>
					</div>
				</div>
			</form>
			<hr />
		</div>
	);
};

export default NuevoRcargo;
