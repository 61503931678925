import React, { useContext } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import PropTypes from "prop-types";

const Reservastd = ({
	reserva: {
		folio,
		nombre,
		apellido,
		llegada,
		salida,
		rsocial,
		grupo,
		info,
		ref,
		reg,
	},
}) => {
	const reservaContext = useContext(ReservaContext);
	const { traerReservaSingle, setModalOpen, borrarReserva, unSetReservas } =
		reservaContext;
	const llegadaF = llegada.split("-");
	const salidaF = salida.split("-");
	const meses = [
		"dud",
		"Enero",
		"Feb",
		"Mar",
		"Abril",
		"Mayo",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	];
	const fllegada =
		llegadaF[2] + "/" + meses[llegadaF[1].replace(/^0+/, "")] + "/" + llegadaF[0];
	const fsalida =
		salidaF[2] + "/" + meses[salidaF[1].replace(/^0+/, "")] + "/" + salidaF[0];

	const delOnClick = async (e) => {
		e.stopPropagation();
		prompt(
			"está a punto de borrar una reservacion, ingrese su clave para confirmar"
		);
		const resp = await borrarReserva(e.target.id);
		unSetReservas();
		console.log(resp);
	};

	const oCtraerReserva = async (e) => {
		e.preventDefault();
		setModalOpen();
		traerReservaSingle(folio);
	};
	return (
		<tr
			onClick={oCtraerReserva}
			className={`clickable ${reg === 1 && "registrado"}`}
		>
			<td>{folio}</td>
			<td>{apellido + " " + nombre}</td>
			<td>{fllegada}</td>
			<td>{fsalida}</td>
			<td>{grupo}</td>
			<td>{info}</td>
			<td>{ref}</td>
			<td className='red'>
				<i
					className='fas fa-times-circle tacha'
					id={folio}
					onClick={delOnClick}
				></i>
			</td>
		</tr>
	);
};
Reservastd.propTypes = {
	reserva: PropTypes.object.isRequired,
};
export default Reservastd;
