export const catalogo = [
	{ clave: "001", descripcion: "DESAY. CONTINENTAL", precio: "60", room: "70" },
	{ clave: "002", descripcion: "HUEVOS DIVORCIADOS", precio: "75", room: "85" },
	{ clave: "003", descripcion: "DESAY. REAL PLAZA", precio: "99", room: "109" },
	{ clave: "004", descripcion: "DESAY. NORTEÑO", precio: "154", room: "164" },
	{ clave: "005", descripcion: "DESAY. MEXICANO", precio: "135", room: "145" },
	{ clave: "006", descripcion: "JUGO DE FRUTA", precio: "26", room: "36" },
	{ clave: "007", descripcion: "ENSALADA DE FRUTAS", precio: "70", room: "80" },
	{ clave: "008", descripcion: "YOGURTH NATURAL", precio: "46", room: "56" },
	{ clave: "009", descripcion: "YOGURTH C/FRUTA", precio: "80", room: "80" },
	{ clave: "010", descripcion: "CEREAL/AVENA", precio: "49", room: "59" },
	{ clave: "011", descripcion: "HOT CAKES NATURALES", precio: "59", room: "69" },
	{
		clave: "012",
		descripcion: "HOT CAKES PREPARADOS",
		precio: "75",
		room: "85",
	},
	{ clave: "013", descripcion: "PAN DULCE", precio: "35", room: "45" },
	{ clave: "014", descripcion: "PAN TOSTADO", precio: "30", room: "35" },
	{ clave: "015", descripcion: "BISQUETS", precio: "25", room: "35" },
	{ clave: "016", descripcion: "ORD.JAMON/TOCINO", precio: "43", room: "53" },
	{ clave: "017", descripcion: "HUEVOS NATURALES", precio: "59", room: "69" },
	{ clave: "018", descripcion: "HUEVOS C/JAMON", precio: "69", room: "79" },
	{ clave: "019", descripcion: "HUEVOS RANCHEROS", precio: "64", room: "74" },
	{ clave: "020", descripcion: "OMELETTE NATURAL", precio: "71", room: "81" },
	{ clave: "021", descripcion: "OMELETTE PREPARADO", precio: "79", room: "89" },
	{ clave: "022", descripcion: "CHILAQUILES", precio: "64", room: "74" },
	{ clave: "023", descripcion: "MOLLETES", precio: "62", room: "72" },
	{ clave: "024", descripcion: "MOLLETES ESPECIALES", precio: "72", room: "82" },
	{ clave: "025", descripcion: "LECHE", precio: "32", room: "42" },
	{ clave: "026", descripcion: "CHOCOLATE", precio: "35", room: "45" },
	{ clave: "027", descripcion: "MALTEADA", precio: "52", room: "62" },
	{ clave: "028", descripcion: "CAFE", precio: "28", room: "38" },
	{ clave: "029", descripcion: "CAPUCHINO", precio: "50", room: "60" },
	{ clave: "030", descripcion: "1/2 MELON C/FRUTA", precio: "72", room: "82" },
	{ clave: "031", descripcion: "COCTEL DE AGUACATE", precio: "85", room: "95" },
	{
		clave: "032",
		descripcion: "COCTEL DE CAMARONES",
		precio: "95",
		room: "105",
	},
	{ clave: "033", descripcion: "CHORIQUESO", precio: "77", room: "87" },
	{ clave: "034", descripcion: "SOPA", precio: "65", room: "75" },
	{ clave: "035", descripcion: "CREMA", precio: "72", room: "82" },
	{ clave: "036", descripcion: "ENSALADA MIXTA", precio: "63", room: "73" },
	{ clave: "037", descripcion: "ENSALADA DEL CHEFF", precio: "74", room: "84" },
	{ clave: "038", descripcion: "SPAGUETTI", precio: "76", room: "86" },
	{ clave: "039", descripcion: "LIMONADA MINERAL", precio: "40", room: "50" },
	{
		clave: "040",
		descripcion: "POLLO FRITO/CANASTA",
		precio: "105",
		room: "115",
	},
	{
		clave: "041",
		descripcion: "PARMESANO/MEZZANINE",
		precio: "115",
		room: "125",
	},
	{ clave: "042", descripcion: "MILANESA C/PAPAS", precio: "144", room: "154" },
	{ clave: "043", descripcion: "STEAK MINUTA", precio: "144", room: "154" },
	{
		clave: "044",
		descripcion: "FILETE A LA TAMPIQUEÑA",
		precio: "177",
		room: "187",
	},
	{ clave: "045", descripcion: "PESCADO", precio: "146", room: "156" },
	{
		clave: "046",
		descripcion: "CHILAQ.C/POLLO-HUEVO",
		precio: "86",
		room: "96",
	},
	{ clave: "047", descripcion: "ENCHILADAS", precio: "83", room: "93" },
	{ clave: "048", descripcion: "TACOS DE POLLO", precio: "79", room: "89" },
	{ clave: "049", descripcion: "TACOS POTOSINOS", precio: "85", room: "95" },
	{ clave: "050", descripcion: "TACOS ROAST BEEF", precio: "129", room: "139" },
	{ clave: "051", descripcion: "TORTAS", precio: "48", room: "58" },
	{ clave: "052", descripcion: "FLAUTAS POTOSINAS", precio: "85", room: "95" },
	{
		clave: "053",
		descripcion: "PUNTAS A LA MEXICANA",
		precio: "138",
		room: "148",
	},
	{ clave: "054", descripcion: "CLUB REAL PLAZA", precio: "85", room: "95" },
	{ clave: "055", descripcion: "SANDWICH", precio: "72", room: "79" },
	{ clave: "056", descripcion: "HAMBURGUESA C/QUESO", precio: "88", room: "98" },
	{ clave: "057", descripcion: "PEPITOS DE RES", precio: "122", room: "132" },
	{ clave: "058", descripcion: "PASTEL DE LA CASA", precio: "60", room: "70" },
	{ clave: "059", descripcion: "JARRA DE CAFE", precio: "105", room: "115" },
	{ clave: "060", descripcion: "HELADO SENCILLO", precio: "54", room: "64" },
	{ clave: "061", descripcion: "TRES MARIA", precio: "75", room: "85" },
	{ clave: "062", descripcion: "HELADO DOBLE", precio: "69", room: "79" },
	{
		clave: "063",
		descripcion: "LIMONADA / NARANJADA",
		precio: "32",
		room: "42",
	},
	{ clave: "064", descripcion: "TE", precio: "25", room: "35" },
	{ clave: "065", descripcion: "REFRESCO", precio: "35", room: "45" },
	{ clave: "066", descripcion: "BUFFET DOMINICAL", precio: "", room: "" },
	{ clave: "067", descripcion: "PAPAS A LA FRANCESA", precio: "59", room: "69" },
	{ clave: "068", descripcion: "BUFFET", precio: "179", room: "" },
	{ clave: "069", descripcion: "MENU SEMANA", precio: "180", room: "" },
	{ clave: "070", descripcion: "MENU DOMINICAL", precio: "215", room: "" },
	{ clave: "071", descripcion: "SINCRONIZADAS", precio: "79", room: "89" },
	{ clave: "074", descripcion: "JUGO GRANDE", precio: "40", room: "50" },
	{ clave: "075", descripcion: "LECHE VASO GRANDE", precio: "38", room: "48" },
	{ clave: "076", descripcion: "AGUA EMBOTELLADA", precio: "14", room: "20" },
	{ clave: "077", descripcion: "HUEVOS MOTULEÑOS", precio: "79", room: "89" },
	{
		clave: "078",
		descripcion: "ENCHILADAS SUIZAS / HUAST",
		precio: "83",
		room: "93",
	},
	{
		clave: "079",
		descripcion: "JARRA AGUA DE FRUTAS",
		precio: "74",
		room: "84",
	},
	{
		clave: "080",
		descripcion: "1/2 JARRA DE AGUA FRUTAS",
		precio: "46",
		room: "56",
	},
	{ clave: "081", descripcion: "ARRACHERA ASADA", precio: "177", room: "187" },
	{
		clave: "082",
		descripcion: "TOSTADO FRANCES - CANELA",
		precio: "40",
		room: "50",
	},
	{ clave: "083", descripcion: "ORDEN DE GUACAMOLE", precio: "59", room: "69" },
	{
		clave: "084",
		descripcion: "ORDEN DE FRIJOLES / ARROZ",
		precio: "38",
		room: "48",
	},
	{ clave: "085", descripcion: "PIEZA DE FRUTA", precio: "13", room: "20" },
	{
		clave: "086",
		descripcion: "ENSALADA DE ATUN / POLLO",
		precio: "73",
		room: "83",
	},
	{
		clave: "087",
		descripcion: "1/2 JARRA DE AGUA MINERAL",
		precio: "54",
		room: "64",
	},
	{
		clave: "088",
		descripcion: "ENTREMES QUESOS / CARNES",
		precio: "95",
		room: "105",
	},
	{ clave: "089", descripcion: "ENTREMES MEXICANO", precio: "85", room: "95" },
	{ clave: "090", descripcion: "JUGO VERDE CH", precio: "44", room: "54" },
	{ clave: "091", descripcion: "QUESO FUNDIDO", precio: "55", room: "65" },
	{
		clave: "092",
		descripcion: "CAMPECHANA CON CAJETA",
		precio: "35",
		room: "45",
	},
	{ clave: "093", descripcion: "PLATO DE FRUTAS", precio: "58", room: "68" },
	{
		clave: "094",
		descripcion: "SABANA A LA PARRILLA",
		precio: "144",
		room: "154",
	},
	{
		clave: "095",
		descripcion: "CECINA C/ ENCHILADAS",
		precio: "162",
		room: "172",
	},
	{ clave: "096", descripcion: "TAMALES HUSTAECOS", precio: "78", room: "88" },
	{ clave: "097", descripcion: "ORDEN DE GORDITAS", precio: "58", room: "68" },
	{ clave: "098", descripcion: "MACHACA CON HUEVO", precio: "106", room: "116" },
	{ clave: "099", descripcion: "CONSUMO", precio: "", room: "" },
	{ clave: "100", descripcion: "SERVICIO", precio: "", room: "" },
	{
		clave: "101",
		descripcion: "OMELETTE C/ CLARAS NAT",
		precio: "81",
		room: "91",
	},
	{
		clave: "102",
		descripcion: "ORD QUESADILLAS MAIZ",
		precio: "64",
		room: "74",
	},
	{
		clave: "103",
		descripcion: "ORD QUESADILLAS HARINA",
		precio: "69",
		room: "79",
	},
	{ clave: "104", descripcion: "CAFE GRANDE", precio: "38", room: "48" },
	{
		clave: "105",
		descripcion: "OMELETTE C/ CLARAS PREP.",
		precio: "95",
		room: "105",
	},
	{ clave: "106", descripcion: "TE GRANDE", precio: "40", room: "50" },
	{ clave: "107", descripcion: "1/2 BUFFET", precio: "90", room: "" },
	{ clave: "108", descripcion: "1/2 JARRA CAFE", precio: "54", room: "64" },
	{ clave: "109", descripcion: "1/2 JARRA JUGO", precio: "70", room: "80" },
	{ clave: "110", descripcion: "CLARAS NATURALES", precio: "68", room: "78" },
	{
		clave: "111",
		descripcion: "CLARAS AL GUSTO PREP",
		precio: "90",
		room: "100",
	},
	{
		clave: "112",
		descripcion: "COPA DE VINO TINTO / BCO",
		precio: "69",
		room: "79",
	},
	{
		clave: "113",
		descripcion: "JARRA DE AGUA MINERAL",
		precio: "94",
		room: "104",
	},
	{ clave: "114", descripcion: "FLAN DE LA CASA", precio: "60", room: "70" },
	{ clave: "125", descripcion: "JUGO VERDE GRANDE", precio: "50", room: "60" },
	{ clave: "126", descripcion: "ALITAS DE POLLO", precio: "135", room: "145" },
	{ clave: "127", descripcion: "ORDEN DE HOT DOG", precio: "55", room: "65" },
	{ clave: "128", descripcion: "TACOS CAMILA", precio: "85", room: "95" },
	{ clave: "129", descripcion: "DESCORCHE", precio: "180", room: "180" },
	{ clave: "115", descripcion: "DURAZNOS EN ALMIBAR", precio: "49", room: "59" },
	{
		clave: "116",
		descripcion: "CERVEZA CLARA CO/XXL/L",
		precio: "42",
		room: "52",
	},
	{
		clave: "117",
		descripcion: "CERVEZA NEGRA IND/VICTORIA",
		precio: "48",
		room: "58",
	},
	{ clave: "118", descripcion: "MICHELADA CLARA", precio: "54", room: "64" },
	{ clave: "119", descripcion: "MICHELADA OBSCURA", precio: "59", room: "69" },
	{
		clave: "120",
		descripcion: "MICHELADA CLARA/CLAMATO",
		precio: "64",
		room: "74",
	},
	{
		clave: "121",
		descripcion: "MICHELADA OBSCURA/CLAMATO",
		precio: "70",
		room: "80",
	},
	{ clave: "122", descripcion: "COPA CLERICOT", precio: "90", room: "100" },
	{ clave: "123", descripcion: "CAFE EXPRESS", precio: "35", room: "45" },
	{ clave: "124", descripcion: "CAFE EXPRESS DOBLE", precio: "45", room: "55" },
	{
		clave: "224",
		descripcion: "CAFE EXPRESS CORTADO",
		precio: "45",
		room: "55",
	},
	{ clave: "99", descripcion: "REAL PLAZA C/FRUTA", precio: "145", room: "155" },
	{ clave: "99", descripcion: "JARRA DE CLERICOT", precio: "235", room: "245" },
	{
		clave: "99",
		descripcion: "1/2 JARRA DE CLERICOT",
		precio: "125",
		room: "135",
	},
	{
		clave: "99",
		descripcion: "JARRA JUGO DE NARANJA",
		precio: "130",
		room: "140",
	},
	{
		clave: "99",
		descripcion: "JARRA JUGO DE ZANAHORIA",
		precio: "140",
		room: "150",
	},
	{ clave: "99", descripcion: "CAJA DE ENCH.CAJA GDE", precio: "385", room: "" },
	{ clave: "99", descripcion: "PZA. HUEVO CRUDO", precio: "20", room: "" },
	{ clave: "99", descripcion: "PZA HUEVO ESTRA", precio: "25", room: "" },
	{
		clave: "99",
		descripcion: "FLANERA YOGURTH/ COTTAGE",
		precio: "25",
		room: "",
	},
	{ clave: "99", descripcion: "PZ GORDITAS", precio: "20", room: "" },
	{ clave: "99", descripcion: "SOPE SENCILLO", precio: "25", room: "" },
	{ clave: "99", descripcion: "SOPE SENCILLO C/GUISO", precio: "35", room: "" },
	{
		clave: "99",
		descripcion: "QUSADILLA SENC. O GUISO",
		precio: "35",
		room: "",
	},
	{ clave: "99", descripcion: "PZA. TAMAL BUFFET", precio: "18", room: "" },
	{ clave: "99", descripcion: "MENUDO", precio: "80", room: "" },
	{ clave: "501", descripcion: "PAGO", precio: "", room: "" },
	{ clave: "502", descripcion: "ABONO EFECTIVO", precio: "", room: "" },
	{ clave: "503", descripcion: "ABONO TARJETA CRED", precio: "", room: "" },
	{ clave: "504", descripcion: "ABONO TARJETA DEB", precio: "", room: "" },
	{ clave: "505", descripcion: "PROPINA", precio: "", room: "" },
];
