import React, { useReducer } from "react";
import axios from "axios";
import CafeteriaContext from "./cafeteriaContext";
import CafeteriaReducer from "./cafeteriaReducer";
import { CHEQUES, CHEQUE, CARGOS, LOADING } from "../types";

const CafeteriaState = (props) => {
	/////////////////////
	//State Inicial
	const initialState = {
		cheques: [{ id: 0 }],
		cheque: {},
		cargos: [],
		cargo: {},
		loading: false,
		vistaReporte: false,
		vistaFacturas: false,
	};

	////////////////////////////
	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};

	////////////////////////
	//se declara el reducer
	const [state, dispatch] = useReducer(CafeteriaReducer, initialState);

	///////////////////////////
	//VISTA DE REPORTE
	const setVistaReporte = (status) => {
		state.vistaReporte = status;
		state.vistaFacturas = false;
		dispatch({ type: CHEQUES, payload: [] });
	};

	///////////////////////////
	//VISTA DE Facturas
	const setVistaFacturas = (status) => {
		state.vistaFacturas = status;
		state.vistaReporte = false;
	};

	///////////////////////////
	//Nuevo Cheque
	const nuevoCheque = async (body) => {
		console.log("NCHEQUE", body);
		await axios
			.post(`/api/cheques`, body, {
				headers: { "Content-Type": "application/json" },
			})
			.then((resp) => {
				//traerCheques();
				console.log(resp.data);
				dispatch({ type: CHEQUE, payload: resp.data.data });
				dispatch({ type: CHEQUES, payload: [] });
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	///////////////////////////
	// Traer cheques todos
	const traerCheques = async () => {
		await axios
			.get(`/api/cheques`)
			.then((resp) => {
				console.log("CHEQUES", resp);
				dispatch({ type: CHEQUES, payload: resp.data.data });
				setLoading(false);
			})
			.catch((error) => {
				console.log(error.response);
				dispatch({ type: "default", payload: [] });
				setLoading(false);
			});
	};

	///////////////////////////
	// Traer Cheques por Status
	const traerChequesFilt = async (status) => {
		let cheques = [];
		await axios
			.get(`/api/cheques`)
			.then((resp) => {
				if (Object.keys(resp.data.data) !== 0)
					resp.data.data.forEach((res) => {
						if (res.status === status) cheques = [...cheques, res];
					});
				if (Object.keys(cheques).length !== 0)
					dispatch({ type: CHEQUES, payload: cheques });
				else dispatch({ type: CHEQUES, payload: [{}] });
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type: "default", payload: [] });
				setLoading(false);
			});
	};

	///////////////////////////
	// Traer cheque por clave
	const traerCheque = async (id) => {
		await axios
			.get(`/api/cheques/${id}`)
			.then((resp) => {
				dispatch({ type: CHEQUE, payload: resp.data.data });
				dispatch({ type: CARGOS, payload: resp.data.data.rcargos });
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};
	///////////////////////////
	//Traer Cheques por Fecha
	const traerChequesFecha = async (fecha) => {
		await axios
			.get(`/api/cheques/dia/${fecha}`)
			.then(async (resp) => {
				if (resp.data.data.length === 0)
					dispatch({ type: CHEQUES, payload: [{ id: 0 }] });
				else await dispatch({ type: CHEQUES, payload: resp.data.data });
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type: CHEQUES, payload: [] });
				setLoading(false);
			});
	};
	///////////////////////////
	//Traer Cheques por Fecha
	const traerChequesFechas = async (li, ls) => {
		await axios
			.get(`/api/cheques/fechas/${li}/${ls}`)
			.then(async (resp) => {
				if (resp.data.data.length === 0)
					dispatch({ type: CHEQUES, payload: [{ id: 0 }] });
				else await dispatch({ type: CHEQUES, payload: resp.data.data });
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type: CHEQUES, payload: [] });
				setLoading(false);
			});
	};

	///////////////////////////
	// Cierra Cheque
	const actualizaCheque = async (body) => {
		await axios
			.patch(`/api/cheques/${body.id}`, body, {
				headers: { "Content-Type": "application/json" },
			})
			.then((resp) => {
				console.log("UPDATE", resp.data);
				dispatch({ type: CHEQUES, payload: [] });
				dispatch({ type: CHEQUE, payload: resp.data.data.Cheque });
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};
	///////////////////////////
	// Agregar Cargo
	const nuevoCargo = async (body) => {
		await axios
			.post(`/api/rcargos`, body, {
				headers: { "Content-Type": "application/json" },
			})
			.then((resp) => {
				console.log(resp.data);
				dispatch({ type: CARGOS, payload: resp.data.data });
				dispatch({ type: LOADING, payload: false });
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type: "", payload: "" });
				dispatch({ type: LOADING, payload: "" });
				setLoading(false);
			});
	};
	////////////////////////////
	// Traer cargos
	const traerCargos = async () => {
		if (Object.keys(state.cheque.rcargos).length !== undefined) {
			await dispatch({ type: CARGOS, payload: state.cheque.rcargos });
			setLoading(false);
		}
	};

	////////////////////////////
	// Eliminar Cargo
	const borrarCargo = async (id, usr) => {
		let ousr = { usuario: usr };
		console.log(ousr);
		try {
			const resp = await axios.delete(
				`/api/rcargos/${id}`,
				{ data: ousr },
				{
					headers: { "Content-Type": "application/json" },
				}
			);
			dispatch({ type: CARGOS, payload: resp.data.data });
			setLoading(false);
			return resp;
		} catch (error) {
			setLoading(false);
			return error.response;
		}
	};

	/////////////////////////////
	// Set Loading
	const setLoading = async (status) => {
		await dispatch({ type: LOADING, payload: status });
	};
	return (
		<CafeteriaContext.Provider
			value={{
				cheques: state.cheques,
				cheque: state.cheque,
				cargos: state.cargos,
				cargo: state.cargo,
				loading: state.loading,
				vistaReporte: state.vistaReporte,
				vistaFacturas: state.vistaFacturas,
				nuevoCargo,
				traerCheque,
				traerCheques,
				traerCargos,
				borrarCargo,
				actualizaCheque,
				nuevoCheque,
				traerChequesFilt,
				traerChequesFecha,
				setLoading,
				setVistaReporte,
				setVistaFacturas,
				traerChequesFechas,
			}}
		>
			{props.children}
		</CafeteriaContext.Provider>
	);
};

export default CafeteriaState;
