import React, { useState, useContext, useEffect } from "react";
import ReactDom from "react-dom";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import CargoContext from "../../contexto/cargo/cargoContext";
import AlertContext from "../../contexto/alerta/alertContext";
const Separacion = ({ cuenta, stado, cerrarM }) => {
	//////////////////////////////////////////////////
	// state y variables
	/////////////////////////////////////////////////
	const cuentaContext = useContext(CuentaContext);
	const { separarCuenta, traerEsclavas, folios } = cuentaContext;
	const cargoContext = useContext(CargoContext);
	const { traerCargosByCuenta, cargos } = cargoContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	let habs = new Array();
	if (Object.keys(cuenta).length > 0) {
		habs = cuenta.habitaciones.map((row) => {
			return row;
		});
	}

	const [habval, setHabval] = useState(0);
	const [folval, setFolval] = useState(cuenta.cuenta);
	const [tarval, setTarval] = useState(0);
	///////////////////////////////////////////////
	// controles
	///////////////////////////////////////////////
	const habSelC = (e) => {
		e.preventDefault();
		e.target.value !== 0 && setHabval(e.target.value);

		const tarifa = habs.filter((r) => r.habitacion == e.target.value);
		setTarval(tarifa[0].tarifa);
	};
	const folSelC = (e) => {
		e.preventDefault();
		e.target.value !== 0 && setFolval(e.target.value);
		traerCargosByCuenta(e.target.value);
	};
	/////////////////////////////////////////////
	///// METODOS
	/////////////////////////////////////////////

	/////////////////
	// submit
	const sepSubmit = async (e) => {
		e.preventDefault();
		const body = {
			habitacion: habval,
			tarifa: tarval,
			cuenta: cuenta.cuenta,
			cliente: cuenta.cliente_id,
			empresa: cuenta.empresa_id,
			reservacion: cuenta.reserva_id,
			folio: folval,
			//registro: usr,
		};
		console.log(body);
		let usr = prompt("Esta por una cuenta de su ingrese su clave para confirmar");
		if (usr === null) {
			return;
		}
		const resp = await separarCuenta(body);
		if (resp.success) {
			setAlert(resp.messages[0], "success");
			cerrarM();
		} else {
			setAlert(resp.data.messages[0], "danger");
		}
	};
	/////////////////////////////////////
	////////////RETURN///////////////
	////////////////////////////////////
	useEffect(() => {
		folios.length === 0 && traerEsclavas();
		if (habs.length !== 0) {
			//	setHabval(habs[0].habitacion);
			//	setTarval(habs[0].tarifa);
		}
		cargos.length === 0 && traerCargosByCuenta(cuenta.cuenta);
	}, [habs, cargos, habval]);
	if (!stado) return null;
	return ReactDom.createPortal(
		<div className='modalOver'>
			<div className='modal-s'>
				<header>
					<i
						className='fas fa-times-circle tacha floatRight'
						onClick={cerrarM}
						style={{ fontSize: "1.7rem" }}
					></i>
					<h2>Separacion de cuenta: {cuenta.cuenta}</h2>
					<p className='medium'>{cuenta.apellido + " " + cuenta.nombre}</p>
				</header>
				<form className='grid-3' onSubmit={sepSubmit}>
					<div>
						<label htmlFor='hab'>Habitacion</label>
						<select name='hab' value={habval} onChange={habSelC}>
							<option value={0}>Seleccione habitacion</option>
							{habs.map((row, i) => {
								return (
									<option key={i} value={row.habitacion}>
										{row.habitacion}
									</option>
								);
							})}
						</select>
					</div>
					<div>
						<label htmlFor='fol'>Folios</label>
						<select name='fol' value={folval} onChange={folSelC}>
							<option value={0}>Seleccione folio</option>
							{folios.map((row) => {
								return (
									row.rel !== "maestra" &&
									row.status === "abierta" && (
										<option key={row.folio} value={row.folio}>
											{row.folio}
										</option>
									)
								);
							})}
						</select>
					</div>
					<div style={{ position: "relative" }}>
						<button className='btn btn-dark abottom w-100'>Separar</button>
					</div>
				</form>
				<h3>cargos a separar:</h3>
				<table>
					<thead>
						<tr>
							<th>Concepto</th>
							<th>Importe</th>
							<th>Abono</th>
							<th>Comentario</th>
							<th>Referencia</th>
						</tr>
					</thead>
					<tbody>
						{cargos.length !== 0 ? (
							cargos.map((r, i) => {
								if (r.cuenta_id == folval) {
									return (
										<tr key={i}>
											<td>{r.concepto}</td>
											<td>{"$" + parseFloat(+r.ieh + +r.importe + +r.iva).toFixed(2)}</td>
											<td>{"$" + parseFloat(r.abono).toFixed(2)}</td>
											<td>{r.comentario}</td>
											<td>{r.referencia}</td>
										</tr>
									);
								}
							})
						) : (
							<tr>
								<td colSpan={5}>nada</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>,
		document.getElementById("portal")
	);
};

export default Separacion;
