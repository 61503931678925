import React, { useReducer } from "react";
import axios from "axios";
import FacturacionContext from "./facturacionContext";
import FacturacionReducer from "./facturacionReducer";
import { EMPRESAS, FOLIO, GUARDAR, FACTURA, LOADING, FACTURAS } from "../types";

const FacturacionState = (props) => {
	//////////////////////
	// STATE INICIAL
	const initialState = {
		loading: false,
		empresas: [],
		empresa: {},
		factura: {},
		facturas: [],
		folio: "",
	};

	////////////////////////////
	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};

	////////////////////////
	//se declara el dispatch
	const [state, dispatch] = useReducer(FacturacionReducer, initialState);

	////////////////////////
	//METODOS

	///////////////////////
	//SET LOADING
	const setLoading = (status) => {
		dispatch({ type: LOADING, payload: status });
	};

	///////////////////////
	//TRAER EMPRESAS
	const traerEmpresas = async () => {
		let salida = null;
		await axios
			.get(`/api/empresas`)
			.then((resp) => {
				dispatch({ type: EMPRESAS, payload: resp.data.data });
				salida = resp.data;
			})
			.catch((error) => {
				console.log(error.reponse);
				salida = error.response;
			});
		return salida;
	};

	///////////////////////////////
	//TRAER NUEVO FOLIO
	const traerFolioA = async () => {
		try {
			const resp = await axios.get(`/api/factura/nuevofolio/A`);
			return resp.data.data;
		} catch (err) {
			return err.response;
		}
	};

	///////////////////////////////
	//TRAER NUEVO FOLIO
	const traerFolioB = async () => {
		try {
			const resp = await axios.get(`/api/factura/nuevofolio/B`);
			return resp.data.data;
		} catch (err) {
			return err.response;
		}
	};

	/////////////////////////
	//GUARDAR DATOS
	const guardarDatos = async (body) => {
		try {
			const resp = await axios.post(`api/empresas`, body, {
				headers: { "Content-Type": "application/json" },
			});
			return resp.data;
		} catch (err) {
			return err.response;
		}
	};

	/////////////////////////
	//ACTUALIZAR DATOS
	const actualizaDatos = async (body) => {
		let salida = null;
		await axios
			.patch(`api/empresas/${body.id}`, body, {
				headers: { "Content-Type": "application/json" },
			})
			.then((res) => {
				console.log(res.data);
				salida = res.data;
			})
			.catch((error) => {
				console.log(error.response);
				salida = error.response;
			});
		return salida;
	};

	/////////////////////////
	//Borrar Empresa
	const eliminarEmpresa = async (body) => {
		if (body.clave !== "X323") {
			return "la clave no es correcta";
		}
		try {
			const resp = await axios.delete(`api/empresas/${body.id}`);
			console.log(resp);
			if (resp.data.success) {
				dispatch({ type: EMPRESAS, payload: [] });
				return resp.data;
			}
		} catch (error) {
			return error.response.data;
		}
	};

	/////////////////////////
	//GENERAR OBJETO FACTURA
	const generarFactura = async (factura) => {
		dispatch({ type: FACTURA, payload: factura });
	};
	/////////////////////////
	// TIMBRADO
	const timbrado = async (body) => {
		await axios
			.post(`api/factura`, body, {
				headers: { "Content-Type": "application/json" },
			})
			.then((resp) => {
				console.log(resp.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	//////////////////////
	// Facturas pór fecha
	const traerFacturasByFechas = async (apertura, cierre) => {
		try {
			const resp = await axios.get(`/api/factura/fechas/${apertura}/${cierre}/`);
			dispatch({ type: FACTURAS, payload: resp.data.data });
			console.log(resp);
			return resp.data;
		} catch (error) {
			console.log(error);
			return "No se encontraron facturas en esta fecha ";
		}
	};

	////////////////////////
	//factura x folio
	const traerFacturaByFolio = async (serie, folio) => {
		try {
			const resp = await axios.get(`/api/factura/xfolio/${serie}/${folio}`);
			if (resp.data.success) {
				dispatch({ type: FACTURA, payload: resp.data.data[0] });
				return resp.data.data[0];
			}
		} catch (err) {
			console.log(err.response);
			return false;
		}
	};

	////////////////////////
	//factura x cuenta
	const traerFacturaByCuenta = async (cta) => {
		limpiarFacturas();
		try {
			const resp = await axios.get(`/api/factura/cuenta/${cta}`);
			if (resp.data.success) {
				dispatch({ type: FACTURAS, payload: resp.data.data });
				return resp.data.data[0];
			}
		} catch (err) {
			console.log(err.response);
			return false;
		}
	};

	//////////////////////
	// traer cargos por cuenta
	const traerFacturas = async () => {
		try {
			const resp = await axios.get(`/api/factura`);
			dispatch({ type: FACTURAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return "No se encontraron Cargos en este turno";
		}
	};

	//////////////////////
	// traer cargos por cuenta
	const FacturasGetByCyD = async () => {
		dispatch({ type: FACTURAS, payload: [] });
		try {
			const resp = await axios.get(`/api/facturacyd`);
			dispatch({ type: FACTURAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return "No se encontraron facturas";
		}
	};

	//////////////////////////////
	//timbrar factura
	const timbrarFactura = async (obj) => {
		try {
			const resp = await axios.post(`/api/factura`, obj, {
				headers: { "Content-Type": "application/json" },
			});
			console.log(resp.data);
			setLoading(false);
			return resp.data;
		} catch (error) {
			console.log(error.response.data);
			setLoading(false);
			return error.response;
		}
	};
	//////////////////////////////
	//timbrar complemente
	const timbrarComplemento = async (obj) => {
		try {
			const resp = await axios.post(`/api/cpago`, obj, {
				headers: { "Content-Type": "application/json" },
			});
			console.log(resp);
			setLoading(false);
			return resp.data;
		} catch (error) {
			setLoading(false);
			return error.response;
		}
	};

	//////////////////////////////
	//timbrar factura
	const timbrarFactura2 = async (obj) => {
		try {
			const resp = await axios.post(`/api/factura2`, obj, {
				headers: { "Content-Type": "application/json" },
			});
			console.log(resp.data);
			setLoading(false);
			return resp.data;
		} catch (error) {
			console.log(error.response.data);
			setLoading(false);
			return error.response;
		}
	};

	///////////////////////////////
	//SELECCION DE EMPRESA
	const seleccionaEmpresa = async (id) => {
		state.empresas.map((row) => {
			if (row.id === id) dispatch({ type: GUARDAR, payload: row });
		});
	};
	///////////////////////////////
	//SELECCION DE EMPRESA
	const limpiarEmpresa = async () => {
		dispatch({ type: GUARDAR, payload: {} });
	};
	///////////////////////////////
	//SELECCION DE EMPRESA
	const limpiarFacturas = async () => {
		dispatch({ type: FACTURAS, payload: [] });
	};

	///////////////////////////////
	//TRAER NUEVO FOLIO
	const traerFolio = async (serie) => {
		await axios
			.get(`/api/factura/nuevofolio/${serie}`)
			.then((resp) => {
				dispatch({ type: FOLIO, payload: resp.data.data.folio });
				return resp.data.data;
			})
			.catch((error) => {
				console.log(error);
			});
	};

	///////////////////////////////
	//reenviar facturas
	const reenviarFactura = async (folio, mail) => {
		let salida = "";
		await axios
			.get(`/api/factura/renvio/${folio}/${mail}/`)
			.then((resp) => {
				salida = resp.data;
			})
			.catch((error) => {
				salida = error.response;
			});
		return salida;
	};

	return (
		<FacturacionContext.Provider
			value={{
				loading: state.loading,
				empresas: state.empresas,
				empresa: state.empresa,
				factura: state.factura,
				facturas: state.facturas,
				folio: state.folio,
				traerEmpresas,
				traerFolioA,
				traerFolioB,
				timbrarFactura,
				timbrarFactura2,
				guardarDatos,
				actualizaDatos,
				generarFactura,
				traerFolio,
				setLoading,
				seleccionaEmpresa,
				traerFacturasByFechas,
				traerFacturas,
				reenviarFactura,
				eliminarEmpresa,
				limpiarEmpresa,
				limpiarFacturas,
				traerFacturaByFolio,
				timbrarComplemento,
				traerFacturaByCuenta,
				FacturasGetByCyD,
			}}
		>
			{props.children}
		</FacturacionContext.Provider>
	);
};

export default FacturacionState;
