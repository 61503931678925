import React, {useState, useEffect, useContext} from "react";
import Cafeteriath from "../cafeteria/Cafeteriath";
import Busqueda from "../cafeteria/Busqueda";
import NuevoCheque from "../cafeteria/NuevoCheque";
import Reporteth from "../cafeteria/Reporteth";
import Reportethf from "../cafeteria/Reportethf";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import Spinner from "../elementos/Spinnermodal";

const Cafeteria = () => {
    ////////////////////////////////////////////
    //CONTEXT
    const {vistaReporte, vistaFacturas, loading} = useContext(CafeteriaContext);

    ////////////////////////////////////////////
    //STATE
    const [newCheque, setNewCheque] = useState(false);
    const [date, setDate] = useState(new Date(Date.now()));
    const [time, setTime] = useState("");
    const [fecha, setFecha] = useState("");
    const options = { weekday: 'long', 
    year: 'numeric', month: 'long', day: 'numeric' };
        
    //////////////////////////////////////////////
    //Métodos
    ////////////////////////////////////////////
    //FECHA
    const reloj = () => {
        let tz = date.getTimezoneOffset() * 60000;
        const [hour, minutes] = 
            [(date.getHours()<10?'0':'')+date.getHours(), (date.getMinutes()<10?'0':'')+date.getMinutes()];
        setTime(hour + ":" + minutes);
        if(fecha==="")
            setFecha(new Date(Date.now()-tz).toISOString().slice(0,10));
    }

    useEffect(()=>{
        setInterval(()=>setDate(new Date(Date.now())),30000);
        reloj();
    },[date,vistaFacturas]);

    return <>
        <h1>
            <div>EL MEZZANINE{vistaReporte||vistaFacturas?" - REPORTES":""}</div>
            {vistaReporte||vistaFacturas?null:
            <span className='floatRight noprint'>
				<i className={newCheque?'fas fa-minus-square':'fas fa-plus-square'} onClick={()=>setNewCheque(!newCheque)}/>
			</span>}
            <div>
                {date.toLocaleDateString('es-ES', options)}
                <div className='floatRight'>{time}hrs</div>
            </div>      
        </h1>
        <hr/>
        {newCheque&&
        <h2 className=''>
            <br/>
            <NuevoCheque/>
            <br/>
            <hr/>
        </h2>}
        <Busqueda setFecha={setFecha} fecha={fecha} />
        <br/>
        {loading && <Spinner/>}
        {vistaReporte?<Reporteth fecha={fecha}/>:vistaFacturas?<Reportethf fecha={fecha}/>:<Cafeteriath fecha={fecha}/>}
        
    </>
}

export default Cafeteria;