import React, { useReducer } from "react";
import axios from "axios";
import RegistroReducer from "./registroReducer";
import RegistroContext from "./registroContext";
import {
	REGISTROS,
	REGISTRO,
	MODAL,
	CLIENTE,
	HABITACIONES,
	HABITACION,
	CANCELACIONES,
	RENTASA,
} from "../types";

const RegistroState = (props) => {
	///////////////////
	//State Inicial
	const initialState = {
		registros: [],
		registro: {},
		habitaciones: [],
		habitacion: {},
		modal: false,
		loading: false,
		cancelaciones: [],
		rentasa: [],
	};

	///////////////////////////
	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};

	////////////////////////
	//se declara el dispatch
	const [state, dispatch] = useReducer(RegistroReducer, initialState);

	////////////////////////
	//generar nueva cuenta
	const crearRegistro = async (body) => {
		try {
			const resp = await axios.post(`/api/registros`, body, {
				headers: { "Content-Type": "application/json" },
			});
			dispatch({ type: REGISTRO, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};

	////////////////////////////
	// borrar registro
	const borrarRegistro = async (rid) => {
		try {
			const resp = await axios.delete(`/api/registros/${rid}`);
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};

	////////////////////////////
	// borrar registro por habitacion
	const borrarRegistroByHab = async (body) => {
		try {
			const resp = await axios.delete(
				`/api/registros/habitacion/${body.hab}`,
				{ data: body },
				{
					headers: { "Content-Type": "application/json" },
				}
			);
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};
	////////////////////////////
	//Patch HABITACION
	const patchHabitacion = async (hab) => {
		let salida = "";
		let body = {
			status: hab.status,
		};
		await axios
			.patch(`api/habitaciones/${hab.habitacion}`, body, {
				headers: { "Content-Type": "application/json" },
			})
			.then((resp) => {
				salida = resp.data;
				//traerVaciasL();
			})
			.catch((error) => {
				salida = error.response;
			});
		return salida;
	};

	////////////////////////////
	//TRAER HABITACIONES DISPONIBLES
	const traerVaciasL = async () => {
		await axios
			.get(`api/habitaciones/status/vaciaL`)
			.then((resp) => {
				dispatch({ type: HABITACIONES, payload: resp.data.data });
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	////////////////////////////
	//TRAER TODAS LAS HABITACIONES
	const traerHabitaciones = async () => {
		await axios
			.get(`/api/habitaciones`)
			.then((resp) => {
				dispatch({ type: HABITACIONES, payload: resp.data.data });
			})
			.catch((error) => {
				console.log(error);
			});
	};

	////////////////////////////
	// Traer todos los registros
	const traerRegistros = async () => {
		try {
			const resp = await axios.get(`/api/registros`, {
				headers: { "Content-Type": "application/json" },
			});
			dispatch({ type: REGISTROS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			console.log(error);
			return error.response;
		}
	};

	///////////////////////////
	//traer registro single
	const traerRegistroSingle = async (regid) => {
		try {
			const resp = await axios.get(`/api/registros/${regid}`, {
				headers: { "Content-Type": "application/json" },
			});
			dispatch({ type: REGISTRO, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			console.log(error);
			return error.response.data;
		}
	};

	/////////////////////////////
	// registro por habiatacion
	const traerRegistrosByHabitacion = async (hab) => {
		try {
			const resp = await axios.get(`/api/registros/busqueda/habitacion/${hab}`, {
				headers: { "Content-Type": "application/json" },
			});
			dispatch({ type: REGISTROS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			console.log(error);
			return error.response.data;
		}
	};

	///////////////////////////
	//registro por apellido
	const traerRegistrosByNombre = async (apellido) => {
		try {
			const resp = await axios.get(`/api/registros/busqueda/nombre/${apellido}`, {
				headers: { "Content-Type": "application/json" },
			});
			dispatch({ type: REGISTROS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			console.log(error);
			return error.response.data;
		}
	};
	//////////////////////
	//Actualizar Cliente
	const patchCliente = async (body) => {
		try {
			const resp = await axios.patch(`api/clientes/${body.id}`, body, {
				headers: { "Content-Type": "application/json" },
			});
			resp.success && dispatch({ type: CLIENTE, payload: resp.data.Cliente });
			return resp.data;
		} catch (error) {
			console.log(error.response);
			return error.response;
		}
	};

	/////////////////////////////
	//Actualizar Registro
	const patchRegistro = async (body) => {
		try {
			const resp = await axios.patch(`api/registros/${body.folio}`, body, {
				headers: { "Content-Type": "application/json" },
			});
			resp.success && dispatch({ type: REGISTRO, payload: resp.data.Registro });
			console.log(resp);
			return resp.data;
		} catch (error) {
			console.log(error.response);
			return error.response;
		}
	};

	/////////////////////////////
	//Cambio de habitacion
	const cambioHab = async (body) => {
		let salida = "";
		try {
			const resp = await axios.patch(`/api/registros/cambio/${body.rid}`, body, {
				headers: { "Content-Type": "application/json" },
			});
			salida = resp;
		} catch (error) {
			salida = error.response;
		}
		return salida;
	};

	///////////////////////////
	//cargosAuditoria traer
	const traerCargosAuditoria = async () => {
		try {
			const resp = await axios.get(`/api/cargos/bulk/1`);
			dispatch({ type: RENTASA, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};

	///////////////////////////
	//cargosAuditoria traer
	const aplicarCargosAuditoria = async (bulkr) => {
		try {
			const resp = await axios.post(`/api/cargos/bulk/${bulkr}`);
			dispatch({ type: RENTASA, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};

	//////////////////////
	// Facturas pór fecha
	const traerCancelaciones = async (li, ls) => {
		try {
			const resp = await axios.get(`/api/cancelaciones/${li}/${ls}/`);
			dispatch({ type: CANCELACIONES, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			console.log(error);
			return "No se encontraron cancelaciones en esta fecha ";
		}
	};

	// set modal
	const setModalOpenReg = () => {
		dispatch({ type: MODAL, payload: true });
		dispatch({ type: REGISTRO, payload: {} });
	};
	// unset modal
	const unsetModalOpenReg = () => {
		dispatch({ type: MODAL, payload: false });
	};
	// unsetReservas
	const unSetRegistros = () => {
		dispatch({ type: REGISTROS, payload: [] });
	};
	//unSet habs
	const unsetHabitaciones = async () => {
		dispatch({ type: HABITACIONES, payload: [] });
	};
	//unSet habs
	const unsetCancelaciones = async () => {
		dispatch({ type: CANCELACIONES, payload: [] });
	};

	return (
		<RegistroContext.Provider
			value={{
				registros: state.registros,
				registro: state.registro,
				habitaciones: state.habitaciones,
				habitacion: state.habitacion,
				cancelaciones: state.cancelaciones,
				modal: state.modal,
				rentasa: state.rentasa,
				crearRegistro,
				traerRegistros,
				setModalOpenReg,
				unsetModalOpenReg,
				unSetRegistros,
				traerRegistroSingle,
				traerRegistrosByHabitacion,
				traerRegistrosByNombre,
				patchRegistro,
				patchCliente,
				traerVaciasL,
				traerHabitaciones,
				patchHabitacion,
				borrarRegistro,
				cambioHab,
				borrarRegistroByHab,
				unsetHabitaciones,
				traerCargosAuditoria,
				aplicarCargosAuditoria,
				traerCancelaciones,
				unsetCancelaciones,
			}}
		>
			{props.children}
		</RegistroContext.Provider>
	);
};

export default RegistroState;
