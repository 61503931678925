import React, { useContext, useEffect, useState } from "react";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
const Rfcpicker = () => {
	const facturacionContext = useContext(FacturacionContext);
	const { empresa, empresas, traerEmpresas, seleccionaEmpresa } =
		facturacionContext;
	const [preempre, setPreempre] = useState("");
	///////////////////////////////////
	const datosfisSel = async (e) => {
		setPreempre(e.target.value);
		empresas.map((row) => {
			if (row.rfc === e.target.value) {
				seleccionaEmpresa(row.id);
			}
		});
	};
	useEffect(() => {
		if (empresas.length === 0) {
			traerEmpresas();
		}
		if (empresa.length > 0) {
			setPreempre(empresa.rfc);
		}
	}, [empresas, empresa]);
	return (
		<div>
			<input
				list='empresas'
				type='text'
				onChange={datosfisSel}
				className='w-100'
				value={preempre}
				placeholder='Datos Fiscales'
			/>
			<datalist id='empresas'>
				{empresas.map((option, index) => {
					return (
						<option key={index} id={option.id} value={option.rfc}>
							{option.rfc}-{option.rsocial}
						</option>
					);
				})}
			</datalist>
		</div>
	);
};

export default Rfcpicker;
