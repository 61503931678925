import React, { useContext, useEffect, useState } from "react";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import FacturacionContext from "../../contexto/facturacion/facturacionContext";
import Facturacionth from "../facturacion/facturacionth";
import Rfcpicker from "../facturacion/Rfcpicker";
import metodosdp from "../../assets/metodosdp";
import base from "../../assets/FacturaCafe.json";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../elementos/Spinnermodal";
import AlertContext from "../../contexto/alerta/alertContext";
import Facturacioncth from "../cnegocios/facturacioncth";
import json from "../../assets/timbrebase33.json";
import Descfacth from "../facturas/Descfacth";

const Facturacion = (props) => {
	//////////////////////////////////
	// CONTEXT
	const { cheque, cargos, traerCheque } = useContext(CafeteriaContext);
	const {
		empresa,
		factura,
		generarFactura,
		traerFolioB,
		loading,
		setLoading,
		timbrarFactura,
		limpiarEmpresa,
		FacturasGetByCyD,
	} = useContext(FacturacionContext);
	const { setAlert } = useContext(AlertContext);
	const { id } = useParams();
	const navigate = useNavigate();
	const [serie, setSerie] = useState("C");
	const [nmC, setNmC] = useState(true);
	const [newmail, setNewmail] = useState("");
	const [clear, setClear] = useState(false);
	//////////////////////////////
	//on change
	const OCnmC = (e) => {
		if (e.target.checked) {
			setNmC(false);
		} else {
			setNmC(true);
			setNewmail("");
		}
	};
	const onChangeNewmail = (e) => {
		setNewmail(e.target.value);
	};

	////////////////////////////////
	//CAMPO GENERICO
	const useField = ({ type }) => {
		const [value, setValue] = useState("");
		const onChange = (event) => {
			setValue(event.target.value);
			if (type === "checkbox") setValue(event.target.checked);
		};
		return { type, value, onChange };
	};

	//////////////////////////////////
	//STATE
	const [mp, setMp] = useState("00");
	const comentario = useField({ type: "text" });

	///////////////////////////////////
	// cambio de
	const metododePago = (mp) => {
		setMp(mp);
		console.log(mp);
	};
	/////////////////////////////////
	//METODOS
	///////////////////////////////
	//money
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	let fmail = empresa.mail;
	if (!nmC) {
		fmail = newmail;
	}
	////////////////////////////////////
	//FACTURA CAFETERIA
	const facturacionB = async () => {
		const total = cheque.importe;
		let importe = total / 1.16;
		const iva = Math.round(importe * 0.16 * 100) / 100;
		const folio = await traerFolioB();
		importe = total - iva;
		let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		let fecha = new Date(Date.now() - tzoffset).toISOString().slice(0, 19);
		if (mp === "00") return;
		let fp = "";
		mp !== "99" ? (fp = "PUE") : (fp = "PPD");
		let usr = prompt(
			"Esta por timbrar la siguiente factura ingrese su contraseña para continuar"
		);
		if (usr === null) {
			return;
		}
		base = {
			...base,
			Comprobante: {
				...base.Comprobante,
				Folio: folio.folio,
				Fecha: fecha,
				FormaPago: mp,
				SubTotal: importe,
				Total: parseFloat(total).toFixed(2),
				Receptor: {
					Rfc: empresa.rfc,
					Nombre: empresa.rsocial,
					DomicilioFiscalReceptor: empresa.cp,
					UsoCFDI: "G03",
				},
				Conceptos: [
					{
						ClaveProdServ: "90101500",
						NoIdentificacion: cheque.id,
						Cantidad: "1",
						ClaveUnidad: "E48",
						Unidad: "Servicio",
						Descripcion: "Consumo",
						ValorUnitario: importe,
						Importe: importe,
						ObjetoImp: "02",
						Impuestos: {
							Traslados: [
								{
									Base: importe,
									Impuesto: "002",
									TipoFactor: "Tasa",
									TasaOCuota: "0.160000",
									Importe: iva,
								},
							],
						},
					},
				],
				Impuestos: {
					TotalImpuestosTrasladados: parseFloat(iva),
					Traslados: [
						{
							Base: importe,
							Impuesto: "002",
							TipoFactor: "Tasa",
							TasaOCuota: "0.160000",
							Importe: iva,
						},
					],
				},
			},
			CamposPDF: {
				Comentario: comentario.value,
				tipoComprobante: "FACTURA",
				calleReceptor: empresa.direccion,
				codigoPostalReceptor: empresa.cp,
				localidadReceptor: "",
				municipioReceptor: empresa.ciudad,
				estadoReceptor: empresa.estado,
			},
		};
		//base.pdf.conceptos = base.Comprobante.Conceptos
		setLoading(true);
		await generarFactura({
			body: base,
			usr: usr,
			mail: fmail,
			cheque_id: cheque.id,
		});
		const fact = {
			body: base,
			usr: usr,
			mail: fmail,
			cheque_id: [cheque.id],
			pdf: {
				nombre: null,
				rsocial: empresa.rsocial,
				rfc: empresa.rfc,
				regimen: empresa.regimen,
				direccion: empresa.direccion,
				cp: empresa.cp,
				entrada: "",
				salida: fecha,
				fpago: mp,
				ciudad: empresa.ciudad,
				estado: empresa.estado,
				pais: "MÉXICO",
				mpago: fp,
				conceptos: [
					{
						fecha: cheque.cierre,
						clave: "90101501",
						cantidad: "1",
						punitario: importe.toFixed(2),
						concepto: "Consumo de Alimentos",
						importe: importe,
						iva: iva,
						ieh: 0,
					},
				],
				hab: "",
				uso: "03 Gastos en General",
				recibe: usr,
				cerro: usr,
				hospedaje: "0",
				cafeteria: moneyForm(importe),
				bar: "0",
				lavanderia: "0",
				cnegocios: "0",
				propinas: "",
				subtotal: moneyForm(importe),
				iva: moneyForm(iva),
				ieh: "0",
				total: moneyForm(total),
				comentarios: comentario.value,
			},
		};
		console.log(fact);
		let respuesta = await timbrarFactura(fact);

		if (respuesta.success) {
			setAlert(respuesta.messages[0], "success");
			navigate(`/cafeteria`);
		} else {
			if (respuesta.data.data.code === "CFDI33118") {
				setAlert("falla decimal", "danger");
			} else if (respuesta.data.data.code === "CFDI33132") {
				setAlert("El RFC del receptor no existe en la lista del SAT", "danger");
			} else if (respuesta.data.data.code === "CFDI40158") {
				setAlert(
					"El régimen Fiscal no coincide con el registrado ante el SAT",
					"danger"
				);
			} else {
				setAlert("falla no identificada", "danger");
			}
		}
	};

	const facturacionC = async () => {
		let fp = "";
		mp !== "99" ? (fp = "PUE") : (fp = "PPD");
		console.log(fp);
		let fact = factura;
		let conceptos = [];
		const importe = fact.Comprobante.Subtotal;
		const iva = fact.Comprobante.Impuestos.TotalImpuestosTrasladados;
		const ieh = fact.Comprobante.Complemento[0].ImpuestosLocales.TotaldeTraslados;
		let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		let fecha = new Date(Date.now() - tzoffset).toISOString().slice(0, 19);
		let timbre;
		fact.Comprobante.Serie = serie;
		fact.Comprobante.FormaPago = mp;
		fact.Comprobante.MetodoPago = fp;
		fact.Comprobante.Fecha = fecha;
		fact.Comprobante.Receptor = {
			Rfc: empresa.rfc,
			Nombre: empresa.rsocial,
			DomicilioFiscalReceptor: empresa.cp,
			UsoCFDI: "G03",
		};
		fact.CamposPDF.Comentarios = comentario.value;
		fact.CamposPDF.calleReceptor = empresa.direccion;
		fact.CamposPDF.codigoPostalReceptor = empresa.cp;
		fact.CamposPDF.localidadReceptor = "";
		fact.CamposPDF.municipioReceptor = empresa.ciudad;
		fact.CamposPDF.estadoReceptor = empresa.estado;
		console.log(fact.Comprobante);
		let usr = prompt("Introduce tu contraseña para continuar.");
		if (usr === null) {
			setAlert("Se canceló la operación.", "danger");
		}
		if (typeof empresa.mail !== "undefined" && mp !== "00") {
			fact.Comprobante.Conceptos.forEach((res, i) => {
				let obj = {};
				obj.fecha = fecha;
				obj.concepto = res.Descripcion;
				obj.clave = res.ClaveProdServ;
				obj.importe = res.Importe;
				obj.cantidad = res.Cantidad;
				obj.punitario = res.ValorUnitario;
				obj.iva = res.Impuestos.Traslados[0].Importe;
				if (res.ClaveProdServ === "90111800")
					//SI ES HOSPEDAJE
					obj.ieh = Math.trunc(res.Importe * 3) / 100;
				else obj.ieh = "0";
				conceptos.push(obj);
			});

			timbre = {
				body: fact,
				usr: usr,
				mail: fmail,
				cuenta_id: 0,
				pdf: {
					nombre: null,
					rsocial: empresa.rsocial,
					rfc: empresa.rfc,
					regimen: empresa.regimen,
					direccion: empresa.direccion,
					cp: empresa.cp,
					entrada: "",
					salida: fecha,
					fpago: mp,
					ciudad: empresa.ciudad,
					estado: empresa.estado,
					pais: "MÉXICO",
					mpago: fp,
					conceptos: conceptos,
					hab: "",
					recibe: usr,
					cerro: usr,
					hospedaje: "",
					cafeteria: "",
					bar: "",
					uso: "03 Gastos en General",
					lavanderia: "",
					cnegocios: "",
					subtotal: moneyForm(fact.Comprobante.SubTotal),
					iva: moneyForm(iva),
					ieh: moneyForm(ieh),
					total: moneyForm(fact.Comprobante.Total),
					comentarios: comentario.value,
				},
			};
		} else {
			setAlert("Revisa la información proporcionada", "danger");
			return;
		}
		console.log("fact", timbre);

		setLoading(true);
		let respuesta = await timbrarFactura(timbre);

		if (respuesta.success) {
			//setAlert(respuesta.messages[0], "success");
			//navigate(`/`)
			setAlert("Factura procesada con éxito", "success");
			limpiarEmpresa();
			FacturasGetByCyD();
			setClear(true);
			comentario.value = "";
			//window.location.reload();
		} else {
			if (respuesta.data.data.code === "CFDI33118") {
				setAlert("falla decimal", "danger");
			} else if (respuesta.data.data.code === "CFDI33132") {
				setAlert("El RFC del receptor no existe en la lista del SAT", "danger");
			} else if (respuesta.data.data.code === "CFDI40158") {
				setAlert(
					"El régimen Fiscal no coincide con el registrado ante el SAT",
					"danger"
				);
			} else {
				setAlert("falla no identificada", "danger");
			}
		}
	};

	useEffect(() => {
		const cheques = async () => {
			if (cheque.id === undefined || Object.keys(cheque).length === 0) {
				traerCheque(id);
				console.log("DATOS", id);
			}
		};
		if (props) setSerie(props.serie);
		if (id !== undefined) {
			setSerie("B");
			cheques();
			if (typeof factura.Comprobante === "undefined")
				if (serie === "B") generarFactura(base);
				else if (serie === "C" || serie === "D") generarFactura(json);
		}
		if (serie === "B") cheques();
		FacturasGetByCyD();
	}, [cargos, cheque]);

	return (
		<>
			<header>
				<h1>
					{serie === "B" ? "El Mezzanine" : "Real Plaza Aguascalientes"}- Facturación
					Serie {serie}
				</h1>
			</header>
			<section className='grid-2 noprint'>
				{serie === "B" ? null : (
					<select className='noprint' onChange={(e) => setSerie(e.target.value)}>
						<option value='C'>Serie C</option>
						<option value='D'>Serie D</option>
					</select>
				)}
				<Rfcpicker />
			</section>
			<section id='fdata' className='grid-2 my-1'>
				<div>
					<p className='medium'>
						RFC: <strong>{empresa.rfc}</strong>
					</p>
					<p className='medium'>
						Ciudad: <strong>{empresa.ciudad}</strong>
					</p>
					<p className='medium'>
						Dirección: <strong>{empresa.direccion}</strong>
					</p>
					<p className='medium'>
						Tel: <strong>{empresa.tel}</strong>
					</p>
				</div>
				<div>
					<p className='medium'>
						Razón S.:<strong> {empresa.rsocial}</strong>
					</p>
					<p className='medium'>
						Estado: <strong>{empresa.estado}</strong>
					</p>
					<p className='medium'>
						C.P.: <strong>{empresa.cp}</strong>
					</p>
					<p className='medium'>
						Email: <strong>{fmail}</strong>
					</p>
				</div>
			</section>
			{loading && <Spinner />}
			{serie === "B" ? (
				<Facturacionth />
			) : serie === "C" || serie === "D" ? (
				<Facturacioncth serie={serie} clear={clear} setClear={setClear} />
			) : (
				<div />
			)}
			<div className='grid-3 noprint my-1'>
				<div>
					<label>Metodo de Pago:</label>
					<select
						value={mp}
						onChange={(e) => {
							metododePago(e.target.value);
						}}
					>
						<option value='00'>-Seleccione una forma de pago-</option>
						{metodosdp.map((res, i) => {
							return (
								<option key={i} value={res.claveSat}>
									{res.nombre}
								</option>
							);
						})}
					</select>
				</div>
				<div>
					<label>Comentario:</label>
					<input {...comentario}></input>
				</div>
				<button
					className='btn-block btn-primary noprint'
					onClick={serie === "B" ? facturacionB : facturacionC}
				>
					Facturar
				</button>
			</div>
			{serie !== "B" && <Descfacth mode={true} />}
		</>
	);
};

export default Facturacion;
