import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import Cargostd from "./Cargostd";
import RegistroContext from "../../contexto/registro/registroContext.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext";
import NuevoCargo from "./NuevoCargo";
import BuscCargosByHab from "./BuscCargosByHab.js";
import AlertContext from "../../contexto/alerta/alertContext";
import tarifas from "../../assets/tarifas";
import Separacion from "../cuentas/Separacion";
import Descfactd from "../facturas/Descfactd";
import Rfcpicker from "../facturacion/Rfcpicker";

const Cargosth = (props) => {
	///////////////////////////////////////////////
	// CONTEXT
	const registroContext = useContext(RegistroContext);
	const {
		crearRegistro,
		habitaciones: habis,
		traerVaciasL,
		borrarRegistroByHab,
		unsetHabitaciones,
	} = registroContext;
	const facturacionContext = useContext(FacturacionContext);
	const { limpiarEmpresa, traerFacturaByCuenta, facturas, empresa } =
		facturacionContext;
	const cuentaContext = useContext(CuentaContext);
	const {
		cuenta,
		traerCuenta,
		cerrarCuentaById,
		cancelarCuentaById,
		traerEsclavas,
		cuentaPatchByFolio,
		folios,
		nuevoFolio,
	} = cuentaContext;

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	//////////////////////////////////////////////////////
	// base state
	const [newCargo, setNewCargo] = useState(false);
	const [hab, setHab] = useState("");
	const [tar, setTar] = useState("");
	const { folio } = useParams();
	const [cuenta_id, setCuenta_id] = useState(folio);
	const [modal, setModal] = useState(false);
	const [separarBody, setSepararBody] = useState({});
	const [cancelable, setCancelable] = useState(false);
	const navigate = useNavigate();
	const {
		cuenta: id,
		apellido,
		reserva_id,
		cliente_id,
		nombre,
		salida,
		habitaciones,
		conceptos,
		subtotal,
		impuestos,
		abonado,
		status,
		info,
		ref,
		grupo,
		rsocial,
		credito,
		maestra,
	} = cuenta;

	//////////////////////////////////////////////
	//valores a numero
	const subtotalf = parseFloat(subtotal);
	const impuestosf = parseFloat(impuestos);
	const abonadof = parseFloat(abonado);
	const subneto = parseFloat(subtotalf) + parseFloat(impuestosf);
	const saldo = parseFloat(subneto) - parseFloat(abonadof);
	///inicializamos titulos
	let habiArr = [];
	let fsalida = "";
	let habitacionesF = "";
	let TarifasF = "";
	let habtit;
	let tartit;
	let habsAct;
	///////////////////////////////////////////
	//si hay cuenta poblamos los titulos acorde
	if (Object.keys(cuenta).length > 0) {
		habitaciones.map((row) => {
			if (status !== "abierta") {
				habitacionesF === ""
					? (habitacionesF += row.habitacion)
					: (habitacionesF += ", " + row.habitacion);

				TarifasF === ""
					? (TarifasF += row.tarifa)
					: (TarifasF += ", " + row.tarifa);
			} else {
				if (row.status === 1) {
					habitacionesF === ""
						? (habitacionesF += row.habitacion)
						: (habitacionesF += ", " + row.habitacion);

					TarifasF === ""
						? (TarifasF += row.tarifa)
						: (TarifasF += ", " + row.tarifa);
				}
			}
		});
		if (habitaciones.length > 1) {
			habtit = "Habitaciones ";
			tartit = "Tarifas ";
		} else {
			habtit = "Habitación ";
			tartit = "Tarifa ";
		}
		///////////////////////////////////////
		//formateo de fechas
		const fechaS = salida.split(" ");
		const salidaF = fechaS[0].split("-");
		const meses = [
			"dud",
			"Enero",
			"Feb",
			"Mar",
			"Abril",
			"Mayo",
			"Jun",
			"Jul",
			"Ago",
			"Sep",
			"Oct",
			"Nov",
			"Dic",
		];
		fsalida =
			salidaF[2] + "/" + meses[salidaF[1].replace(/^0+/, "")] + "/" + salidaF[0];

		///////////////////////////////////////
		// aislamso habitaciones actuales
		habsAct = habitaciones.filter((row) => {
			if (row.status === 1) {
				return true;
			}
		});
		habiArr = habsAct.map((r) => {
			return r.habitacion;
		});
	}
	const habSolo = habis.map((row) => {
		return row.habitacion;
	});
	////nuevo cargo togg controla el despliegue del formulario
	const toggleNewCargo = () => {
		if (newCargo) {
			setNewCargo(false);
		} else {
			setNewCargo(true);
		}
	};

	///////////////////////////////////////
	//controladires de haitaciones y tarifas
	const onChangeSethab = (e) => {
		setHab(e.target.value);
	};
	const onChangeSetTar = (e) => {
		setTar(e.target.value);
	};

	///////////////////////////////////////////////////////////////////////
	// regHabs registra habitaciones o desenlaza habitaciones de la cuenta
	const regHabs = async (e) => {
		e.preventDefault();
		let vl = await traerVaciasL();
		if (habiArr.includes(parseInt(hab))) {
			if (habsAct.length <= 1) {
				setAlert("la cuenta debe tener al menos una habitacion", "danger");
				return;
			}
			let usr = prompt(
				"Esta por darle salida a la habitacion ingrese su clave para continuar"
			);
			if (usr === null) {
				return;
			} else {
				let delbod = {
					hab: hab,
					nombre: nombre,
					cuenta: id,
					info: info,
					razon: "salida por separacion de cuenta",
					tarifa: tar,
					usr: usr,
				};
				const resp = await borrarRegistroByHab(delbod);
				console.log(resp);
				unsetHabitaciones();
				setAlert("habitacion eliminada de la cuenta", "danger");
				traerCuenta(id);
			}
		} else {
			if (habSolo.includes(parseInt(hab))) {
				let usr = prompt("Desea añadir la habitación " + hab + " a la cuenta");
				let body = {
					registros: [
						{
							reserva_id: reserva_id,
							cliente_id: cliente_id,
							cuenta_id: id,
							titular: nombre,
							tarifa: tar,
							habitacion: hab,
							registro: usr,
						},
					],
				};
				const resp = await crearRegistro(body);
				if (resp.success) {
					traerCuenta(id);
					setAlert("habitación añadida", "success");
				}
			} else {
				traerCuenta(id);
				setAlert("habitación no disponible", "danger");
			}
		}
	};

	///////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + Math.round(numero * 100) / 100;
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	//////////////////////////////
	//Cerrar Cuenta
	const cerrarCuenta = async (e) => {
		e.preventDefault();
		const cerro = prompt(
			`Esta a punto de cerrar la cuenta para confirmar ingrese su clave`
		);
		if (cerro !== null) {
			const resp = await cerrarCuentaById(id, cerro);
			if (resp.success) {
				setAlert("La cuenta ha sido cerrada", "success");
				traerCuenta(id);
			} else {
				setAlert(resp.messages, "danger");
			}
		} else {
			setAlert("Operación cancelada", "danger");
		}
	};

	/////////////////////////////
	//ir a facturar
	const irafactura = () => {
		limpiarEmpresa();
		navigate(`/facturacion/${folio}`);
	};

	///////////////////////////
	// cancelar cuenta
	const cancelarFac = async (e) => {
		e.preventDefault();
		const cerro = prompt(
			`Esta a punto de cerrar la cuenta para confirmar ingrese su clave`
		);
		if (cerro !== null) {
			const resp = await cancelarCuentaById(id, cerro);
			if (resp.success) {
				setAlert("La cuenta ha sido cancelada", "success");
				setCancelable(false);
				traerCuenta(id);
			} else {
				setAlert(resp.data.messages, "danger");
			}
		} else {
			setAlert("Operación cancelada", "danger");
		}
	};

	//////////////////////
	//separar
	const sepaload = async () => {
		const resp = await traerCuenta(id);
		if (resp.success && resp.data.conceptos !== "Cuenta sin cargos") {
			setSepararBody(resp.data);
			setModal(true);
		} else {
			setAlert("Cuenta Inválida o sin Cargos que separar", "danger");
		}
	};

	/////////////////////
	/// añadir folio
	const addFolio = async () => {
		let usr = prompt("Esta seguro que desea añadir un nuevo folio?");
		if (usr === null) {
			return;
		}
		const resp = await nuevoFolio(id);
		console.log(resp);
	};

	/////////////////////
	// select esclava
	const selectEsclava = (e) => {
		if (e !== 0) {
			traerCuenta(e.target.value);
		}
	};
	////////////////////
	const crediTogg = () => {
		let bod = { folio: id, credito: 0 };
		let bod2 = { folio: id, credito: 1000 };
		credito > 0 && status === "abierta"
			? cuentaPatchByFolio(bod)
			: cuentaPatchByFolio(bod2);
		traerCuenta(id);
	};
	////////////////////
	const setEmpresa = () => {
		let bod = { folio: id, empresa_id: empresa.id };
		let usr = prompt("Esta por Asignar una empresa a la cuenta");
		if (usr === null) {
			return;
		}
		typeof empresa !== "undefined" && cuentaPatchByFolio(bod);
		traerCuenta(id);
	};

	/////////////////////////////////////////////
	// use Effect
	useEffect(() => {
		if (Object.keys(cuenta).length === 0) {
			traerCuenta(cuenta_id);
		}
		if (Object.keys(habis).length === 0) {
			traerVaciasL();
		}
		if (folios.length === 0) {
			traerEsclavas(id);
		}
		if (Array.isArray(conceptos)) {
			const conceptosfiltrados = conceptos.filter((r) => r.status === 0);
			if (conceptosfiltrados.length === 0 && status === "abierta") {
				setCancelable(true);
			}
		}
		if (Object.keys(cuenta).length !== 0) {
			traerFacturaByCuenta(id);
		}
	}, [cuenta, conceptos]);

	////////////////////////////////////////////
	//Return data apartir de aqui es jsx
	return (
		<>
			{<BuscCargosByHab />}
			{/* Busqueda por habitacion*/}

			<div id='cargosMainWrapp' className='xmodalOver relative'>
				{/*inicia cabezal*/}
				<h1>
					{apellido + " " + nombre}
					{status === "abierta" ? (
						<>
							<span className='floatRight'>
								{newCargo ? (
									<i className='fas fa-minus-square' onClick={toggleNewCargo}></i>
								) : (
									<i className='fas fa-plus-square' onClick={toggleNewCargo}></i>
								)}
							</span>
						</>
					) : (
						<span className='large floatRight' style={{ color: "red" }}>
							{status}
						</span>
					)}
					<span className='lead' style={{ fontWeight: "100" }}>
						Cuenta: <strong>{id}</strong>
					</span>
				</h1>
				{/*Finaliza Cabezal*/}

				<div id='cuentaData' className='w-100'>
					<div>
						<div className='grid-4 w-100' style={{ gridGap: "10px" }}>
							<div className='gspan-2'>
								<p className=''>
									{habtit}: <strong>{habitacionesF}</strong>
								</p>
								<p>
									{tartit}:{" "}
									<strong
										className='small'
										style={{ fontFamily: "sans-serif", fontStretch: "extra-condensed" }}
									>
										{TarifasF}
									</strong>
								</p>
								<p>
									Observaciones: <strong>{info}</strong>
								</p>
								<p>
									Referencia: <strong>{ref}</strong>
								</p>
							</div>
							<div className=''>
								<p>
									Salida:
									<strong> {fsalida}</strong>
								</p>
								<p>
									Grupo:
									<strong> {grupo}</strong>
								</p>
								<p>
									Empresa:
									<strong> {rsocial === null ? "Sin datos fiscales" : rsocial}</strong>
								</p>
							</div>
							<div>
								<p>
									Saldo: <strong>{moneyForm(saldo)}</strong>
								</p>
								<p>
									Cargo a Habitación:{" "}
									<i
										className={`fas fa-utensils floatRight large ${
											credito > 0 ? "on" : "off"
										}`}
										style={{ marginTop: "-10px" }}
										onClick={crediTogg}
									></i>
								</p>
								<div className='grid-3 w-100' style={{ gridGap: "5px" }}>
									<p className='gspan-3'>Folios de la cuenta:</p>
									{Array.isArray(folios) ? (
										<div className='gspan-2'>
											<select name='folios' id='' onChange={selectEsclava}>
												<option key={0} value={0}>
													Seleccione un folio
												</option>
												{folios.map((row) => {
													return (
														<option key={row.folio} value={row.folio}>
															{row.folio}
														</option>
													);
												})}
											</select>
										</div>
									) : (
										<div className='gspan-2'></div>
									)}
									{maestra === null && (
										<button className='btn btn-dark floatRight' onClick={addFolio}>
											Folio+
										</button>
									)}
								</div>
							</div>
						</div>
						<div
							className={
								status === "abierta" && habitaciones.length > 1 ? "grid-6" : "grid-5"
							}
							style={{ marginTop: "10px" }}
						>
							<form action='' onSubmit={regHabs} className='grid-3 gspan-3'>
								<input
									name='setHab'
									type='number'
									value={hab}
									placeholder='Habitacion'
									onChange={onChangeSethab}
									list='vaciasl'
									className=''
									required
								/>
								<datalist id='vaciasl'>
									{habSolo.map((res, i) => {
										return (
											<option key={i} value={res}>
												{res}
											</option>
										);
									})}
								</datalist>
								<input
									name='setTar'
									type='text'
									list='tarifas'
									value={tar}
									placeholder='tarifa'
									onChange={onChangeSetTar}
									className=''
									required
								/>
								<datalist id='tarifas'>
									{tarifas.map((res, i) => {
										return (
											<option key={i} value={res.Nombre}>
												{res.Nombre}
											</option>
										);
									})}
								</datalist>
								<button type='submit' className='btn btn-dark'>
									{" "}
									Habitacion +
								</button>
							</form>
							<div className='grid-2 gspan-2'>
								<Rfcpicker />
								<button className='btn btn-dark' onClick={setEmpresa}>
									Asignar
								</button>
							</div>
							{status === "abierta" && habitaciones.length > 1 && (
								<button className='btn btn-dark' type='button' onClick={sepaload}>
									Separar Cuenta
								</button>
							)}
						</div>
					</div>

					<Separacion
						stado={modal}
						cuenta={separarBody}
						cerrarM={() => {
							setModal(false);
						}}
					/>
					{newCargo && <NuevoCargo />}
				</div>
				{/*Find de cuentadata*/}

				{/*Inicia facturas si el status es facturada*/}
				{facturas.length !== 0 && (
					<>
						<h3>Facturas de la cuenta</h3>
						<table id='car' className='w-100'>
							<thead>
								<tr>
									<th>Cuenta</th>
									<th>Fecha</th>
									<th>Razón Social</th>
									<th>Cliente</th>
									<th>Folio</th>
									<th>Folio Fiscal</th>
									<th>PDF</th>
									<th>XML</th>
									<th>Enviar</th>
								</tr>
							</thead>
							<tbody>
								{facturas.length !== 0 &&
									facturas.map((res) => <Descfactd key={res.id} factura={res} />)}
							</tbody>
						</table>
						<h3>Cargos de la Cuenta</h3>
					</>
				)}
				{/*Termina facturas*/}

				{/*Inicia tabla de cargos*/}
				<table id='tablaCargos' className='w-100'>
					<thead>
						<tr>
							<th>Hab.</th>
							<th>Fecha</th>
							<th>Hora</th>
							<th>Concepto</th>
							<th>Metodo de Pago</th>
							<th>Importe</th>
							<th>Abono</th>
							<th>Referencia</th>
							<th>Comentario</th>
							<th>Cajero</th>
							<th>Folio</th>
							<th>Borrar</th>
						</tr>
					</thead>
					<tbody>
						{Array.isArray(conceptos)
							? conceptos.map((res) => <Cargostd key={res.id} cargo={res} />)
							: null}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan='6' />
							<th scope='row'>total</th>
							<td>
								<strong className='medium'>{moneyForm(subneto)}</strong>
							</td>
							<th scope='row'>Abonado</th>
							<td>
								<strong className='medium'>{moneyForm(abonado)}</strong>
							</td>
							<th scope='row'>saldo</th>
							<td>
								<strong className='medium'>{moneyForm(saldo)}</strong>
							</td>
						</tr>
					</tfoot>
				</table>
				{/*Finaliza tabla cargos*/}

				{/*Inciia lógica de cierre*/}
				<div className='w-100'>
					{saldo > -0.009 &&
					saldo < 0.009 &&
					status === "abierta" &&
					conceptos !== "Cuenta sin cargos" ? (
						<button
							onClick={cerrarCuenta}
							className='btn btn-dark floatRight cerrarCuentabtn my-1'
						>
							Cerrar Cuenta
						</button>
					) : null}
					{status === "cerrada" && (
						<button
							onClick={irafactura}
							className='btn btn-dark floatRight cerrarCuentabtn my-1'
						>
							Facturar
						</button>
					)}
					{cuenta.status !== "cancelada" &&
						(conceptos === "Cuenta sin cargos" || cancelable) && (
							<button
								onClick={cancelarFac}
								className='btn btn-dark floatRight cerrarCuentabtn my-1'
							>
								Cancelar Cuenta
							</button>
						)}
				</div>
				{/*Temina lógica de cierre*/}
			</div>
			{/*Termina cargosMainWrapp*/}
		</>
	);
};

export default Cargosth;
