import React, { useContext, useEffect, useState } from "react";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";

const Facturacionth = () => {
    ////////////////////////////////////
    //CONTEXTO
    const {cheque} = useContext(CafeteriaContext);

    ////////////////////////////////////
    //STATE
    const [status,setStatus] = useState("");
    const [importeDesc, setImporteDesc] = useState(0);
    const [iva, setIva] = useState(0)

    const cuenta = async() =>{
        let iva = 0, importe = 0;
        switch(cheque.status){
            case "facturada":
                setStatus("YA ESTÁ FACTURADA");
                break;
            case "abierta":
                setStatus("LA CUENTA NO ESTÁ CERRADA");
                break;
            case "trasnferida":
                setStatus("LA CUENTA SE TRANSFIRIÓ A HABITACIÓN");
                break;
            case "":
                setStatus(cheque.rcargos);
                break;
            default:
                break;
        };
        importe = (parseFloat(cheque.importe) * ((100-cheque.descuento)/100))/1.16;
        iva = importe * 0.16;
        importe = cheque.importe - iva;
        setImporteDesc(importe);
        setIva(iva);
    }
    useEffect(()=>{
        cuenta();
    },[cheque,status])

    return (
        <>
        <table className="w-100">
            <thead>
                <tr className="">
                    <th>REFERENCIA</th>
                    <th width="">CLAVE</th>
                    <th width="">CANTIDAD</th>
                    <th width="">CONCEPTO</th>
                    <th width="">P. UNITARIO</th>
                    <th width="">IMPORTE</th>
                </tr>
            </thead>
            <tbody>
                {status === ''?
                <tr>
                    <td>{cheque.id}</td>
                    <td>90101501</td>
                    <td>1</td>
                    <td>Consumo de Alimentos</td>
                    <td>${parseFloat(importeDesc).toFixed(2)}</td>
                    <td>${parseFloat(importeDesc).toFixed(2)}</td>
                </tr>
                :<tr><td colSpan="4">{status}</td></tr>}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="4"/>
                    <th>SUBTOTAL</th>
                    <td>${parseFloat(importeDesc).toFixed(2)}</td>
                </tr>
                <tr>
                    <td colSpan="4"/>
                    <th>IVA</th>
                    <td>${parseFloat(iva).toFixed(2)}</td>
                </tr>
                <tr>
                    <td colSpan="4"/>
                    <th>TOTAL</th>
                    <td>${parseFloat(importeDesc+iva).toFixed(2)}</td>
                </tr>
            </tfoot>
            </table>
            </>
        )
}

export default Facturacionth;