import React, { useContext, useEffect, useState } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import RegistroContext from "../../contexto/registro/registroContext.js";

const Inireporeserva = () => {
	const registroContext = useContext(RegistroContext);
	const reservaContext = useContext(ReservaContext);
	const {
		reservaciones,
		traerSalidas,
		traerReservasFechas,
		traerReservasRec,
		salidas,
	} = reservaContext;
	const { habitaciones, traerHabitaciones } = registroContext;

	const [htotal, setHtotal] = useState(0);
	const [hvacial, setVacial] = useState(0);
	const [hocupada, setOcupada] = useState(0);
	const [hvacias, setVacias] = useState(0);
	const [hasignadal, setAsignadal] = useState(0);
	const [hasignadas, setAsignadas] = useState(0);
	const [habisal, setHabisal] = useState(0);
	const [habiIn, setHabiIn] = useState(0);
	const [dispo, setDispo] = useState(0);
	const [ocu, setOcu] = useState(0);
	const [sus, setSus] = useState(0);
	const [out, setOut] = useState(0);

	const numeros = () => {
		setHtotal(habitaciones.length);
		setVacial(habitaciones.filter((row) => row.status === "vaciaL").length);
		setOcupada(habitaciones.filter((row) => row.status === "ocupada").length);
		setVacias(habitaciones.filter((row) => row.status === "vaciaS").length);
		setAsignadal(habitaciones.filter((row) => row.status === "asignadaL").length);
		setAsignadas(habitaciones.filter((row) => row.status === "asignadaS").length);
	};

	let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
	let fecha = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);

	const [fechaState, setFechaState] = useState(fecha);
	let salidasHoy = salidas.filter((row) => row.salidas === fechaState);
	let reservasHoy = reservaciones.filter((row) => row.llegada === fechaState);
	const monthNames = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	let time = fechaState + "T00:00:01";
	const d = new Date(time);
	const dia = d.getDate() + " " + monthNames[d.getMonth()];

	//////////////////////////////
	//date change
	const onDateChange = (e) => {
		traerSalidas(e.target.value);
		let body = {
			Rinicio: e.target.value,
			Rend: e.target.value,
		};
		traerReservasFechas(body);
		setFechaState(e.target.value);
	};
	////////////////////////////////
	//calcular salidas por habitación
	const salidasXhab = () => {
		let salcont = 0;
		salidasHoy.forEach((r) => {
			salcont = salcont + r.habitaciones.length;
		});
		setHabisal(salcont);
	};
	////////////////////////////////
	//calcular llegadas por habitación
	const llegadasXhab = () => {
		let salcont = 0;
		reservasHoy.forEach((r) => {
			salcont = salcont + r.habitaciones;
		});
		setHabiIn(salcont);
	};
	/////////////////////////////
	// calcular pie
	const piepop = () => {
		let disp = ((hvacial + hasignadal) / htotal) * 100;
		let ocu = (hocupada / htotal) * 100;
		let sus = (hvacias / htotal) * 100;
		let out = (hasignadas / htotal) * 100;
		setDispo(3.6 * Math.round(disp));
		setOcu(3.6 * (Math.round(disp) + Math.round(ocu)));
		setSus(3.6 * (Math.round(disp) + Math.round(ocu) + Math.round(sus)));
		setOut(
			3.6 *
				(Math.round(disp) + Math.round(ocu) + Math.round(sus) + Math.round(out))
		);
	};
	useEffect(() => {
		let body2 = {
			Rinicio: "2022-11-30",
			Rend: "2022-11-30",
		};
		salidas.length === 0 && traerSalidas(fechaState);
		reservaciones.length === 0 && traerReservasFechas(body2);
	}, [salidas, reservaciones, fechaState]);

	return (
		<div className='w-100 grid-3'>
			<h1 className='gspan-2'>Estado del Hotel</h1>
			<form>
				<input className='datemes' name='mes' type='date' onChange={onDateChange} />
			</form>
			{/*Inicia piechart*/}
			<section className=''>
				<h2 className=''>
					Total de Habitaciones: <strong>{htotal}</strong>
				</h2>
				<div
					className='pie'
					style={{
						background: `repeating-conic-gradient(from 0deg, 
							lightgreen 0deg ${dispo}deg, 
							lightgrey ${dispo}deg ${ocu}deg, 
							pink ${ocu}deg ${sus}deg, 
							lightblue ${sus}deg 360deg )`,
					}}
				></div>
				<ul
					className='my-1 card'
					style={{ backgroundColor: "rgba(255,255,255,0.5)", borderRadius: "10px" }}
				>
					<li id='dispo' className='rel'>
						<p className='large2'>
							Disponibles:<strong> {hvacial + hasignadal}</strong>
						</p>
					</li>
					<li id='ocu' className='rel'>
						<p className='large2'>
							Ocupadas: <strong>{hocupada}</strong>
						</p>
					</li>
					<li id='sus' className='rel'>
						<p className='large2'>
							Sucias: <strong>{hvacias}</strong>
						</p>
					</li>
					<li id='out' className='rel'>
						<p className='large2'>
							Fuera de servicio: <strong>{hasignadas}</strong>
						</p>
					</li>
				</ul>
			</section>
			{/*termina piechart*/}
			{/*Col 2 salidas del dia*/}
			<section className=''>
				<article>
					<h3>
						Salidas pendientes para {dia}
						<span className='large'>{"  " + salidas.length}</span>
					</h3>
					<table className='w-100 left'>
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Habs.</th>
							</tr>
						</thead>
						<tbody>
							{salidas.length === 0 ? (
								<tr>
									<td colSpan={2}>
										<h2>Sin salidas para este día</h2>
									</td>
								</tr>
							) : (
								salidas.map((row, i) => {
									if (row.status === 1) {
										return (
											<tr key={i}>
												<td>{row.apellido + " " + row.nombre}</td>
												<td>{row.habitacion}</td>
											</tr>
										);
									}
								})
							)}
						</tbody>
					</table>
					<h3>
						Salidas registradas {dia}
						<span className='large'>{"  " + salidas.length}</span>
					</h3>
					<table className='w-100 left'>
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Habs.</th>
							</tr>
						</thead>
						<tbody>
							{salidas.length === 0 ? (
								<tr>
									<td colSpan={2}>
										<h2>Sin salidas para este día</h2>
									</td>
								</tr>
							) : (
								salidas.map((row, i) => {
									if (row.status === 0) {
										return (
											<tr key={i}>
												<td>{row.apellido + " " + row.nombre}</td>
												<td>{row.habitacion}</td>
											</tr>
										);
									}
								})
							)}
						</tbody>
					</table>
				</article>
			</section>
			<article>
				<h3>
					Reservas para {dia}
					<span className='large'>{"  " + habiIn}</span>
				</h3>
				<table className='w-100 left'>
					<thead>
						<tr>
							<th>Nombre</th>
							<th>Habs.</th>
						</tr>
					</thead>
					<tbody>
						{reservaciones.length === 0 ? (
							<tr>
								<td colSpan={2}>
									<h2>Sin reservas para este día</h2>
								</td>
							</tr>
						) : (
							reservaciones.map((row, i) => {
								return (
									<tr key={i}>
										<td>{row.apellido + " " + row.nombre}</td>
										<td>{row.habitaciones}</td>
									</tr>
								);
							})
						)}
					</tbody>
				</table>
			</article>
		</div>
	);
};

export default Inireporeserva;
