import React, { useContext, useEffect, useState } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext";
import NuevoRegistro from "../registros/NuevoRegistro.js";
//import Spinner from "../elementos/Spinnermodal";
//import facturacionContext from "../../contexto/facturacion/facturacionContext";
import AlertContext from "../../contexto/alerta/alertContext";

const ReservaSingle = () => {
	const reservaContext = useContext(ReservaContext);
	//const { empresa, empresas, actualizaDatos } = useContext(facturacionContext);
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const [registrar, setRegistrar] = useState(false);
	const [reserva, setReserva] = useState({});
	const { reservacion, unsetModalOpen, patchReserva, patchCliente } =
		reservaContext;
	console.log(reserva);

	//const reservaLength = Object.keys(reservacion).length;

	const { id, reg } = reservacion;
	const closeModal = () => {
		unsetModalOpen();
	};

	const actualizaReserva = async () => {
		let usr = prompt("Introduce tu contraseña");
		if (usr) {
			const reser = {
				//modifico : usr,
				folio: reserva.folio,
				llegada: reserva.llegada,
				salida: reserva.salida,
				personas: reserva.personas,
				habitaciones: reserva.habitaciones,
				grupo: reserva.grupo,
				info: reserva.info,
				ref: reserva.ref,
				cliente: reserva.cliente,
				empresa: reserva.empresa,
				reservo: reserva.reservo,
				cancelo: reserva.cancelo,
			};
			console.log(reser);

			const resp1 = await patchReserva(reser);
			console.log(resp1);
			if (reserva.mail === "") reserva.mail = null;
			const cli = {
				id: reserva.cliente_id,
				nombre: reserva.nombre,
				apellido: reserva.apellido,
				ciudad: reserva.ciudad,
				tel: reserva.tel,
				estado: reserva.estado,
				mail: reserva.mail,
			};
			const resp2 = await patchCliente(cli);
			console.log(cli);
			console.log(resp2);
			if (resp1.success || resp2.success) {
				setAlert("Datos actualizados", "success");
			} else {
				setAlert("Error al actualizar verifique los datos", "danger");
			}
		}
	};

	const regTogg = () => {
		registrar ? setRegistrar(false) : setRegistrar(true);
	};

	useEffect(() => {
		if (Object.keys(reserva).length === 0) setReserva(reservacion);
	}, [reservacion, reserva]);

	if (!registrar) {
		return (
			<>
				<div className='modalOver' />
				<div id={id} className='grid-4 modal smallgapx'>
					<h2 className='gspan-4'>
						Reserva: {id}{" "}
						<span className='floatRight'>
							<i className='fas fa-times-circle' onClick={closeModal}></i>
						</span>
					</h2>
					<div className='gspan-2 grid-2'>
						<div>
							<p className='small bold'>Nombre</p>
							<input
								type='text'
								value={reserva.nombre}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, nombre: e.target.value })}
							></input>
						</div>
						<div>
							<p className='small bold'>Apellido</p>
							<input
								type='text'
								value={reserva.apellido}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, apellido: e.target.value })}
							></input>
						</div>

						<div className='gspan-2'>
							<p className='small bold'>Comentarios</p>
							<input
								type='text'
								value={reserva.info}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, info: e.target.value })}
							></input>
						</div>
						<div className='gspan-2'>
							<p className='small bold'>Referencia</p>
							<input
								type='text'
								value={reserva.ref}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, ref: e.target.value })}
							></input>
						</div>
					</div>

					<div className='text-right'>
						<p className='small bold'>No.personas</p>
						<input
							type='text'
							value={reserva.personas}
							onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
							readOnly={true}
							onChange={(e) => setReserva({ ...reserva, personas: e.target.value })}
						></input>

						<p className='small bold'>No. habitaciones</p>
						<input
							type='text'
							value={reserva.habitaciones}
							onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
							readOnly={true}
							onChange={(e) =>
								setReserva({ ...reserva, habitaciones: e.target.value })
							}
						></input>

						<p className='small bold'>Grupo</p>
						<input
							type='text'
							value={reserva.grupo}
							onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
							readOnly={true}
							onChange={(e) => setReserva({ ...reserva, grupo: e.target.value })}
						></input>
					</div>
					<div className='text-right'>
						<p className='small bold'>LLegada</p>
						<input
							type='date'
							value={reserva.llegada}
							onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
							readOnly={true}
							onChange={(e) => setReserva({ ...reserva, llegada: e.target.value })}
						></input>

						<p className='small bold'>Salida</p>
						<input
							type='date'
							value={reserva.salida}
							onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
							readOnly={true}
							onChange={(e) => setReserva({ ...reserva, salida: e.target.value })}
						></input>
						<p className='small bold'>.</p>
						<button className='btn btn-dark w-100' onClick={actualizaReserva}>
							Actualizar
						</button>
					</div>
					<div className='gspan-4 text-center'>
						{reg === 0 ? (
							<button className='btn btn-dark' onClick={regTogg}>
								Registrar
							</button>
						) : null}
					</div>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div className='modalOver'>
					<div className='modal'>
						<NuevoRegistro />
						<div className='gspan-4 text-center my-05'>
							<button className='btn' onClick={regTogg}>
								regresar
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
};
export default ReservaSingle;
