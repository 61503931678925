import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import alertContext from "../../contexto/alerta/alertContext";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import NuevoRcargo from "./NuevoRcargo";
import Rcargostd from "./Rcargostd";
import Spinner from "../elementos/Spinnermodal";
import cuentaContext from "../../contexto/cuenta/cuentaContext";
import CargoContext from "../../contexto/cargo/cargoContext";
import Cheque from "./Cheque";

const Rcargosth = () => {
	////////////////////////////////////////////
	// CONTEXT
	const cafeteriaContext = useContext(CafeteriaContext);
	const { traerCheque, cheque, actualizaCheque, loading, setLoading, cargos } =
		cafeteriaContext;
	const { setAlert } = useContext(alertContext);
	const { traerCuentaByHab } = useContext(cuentaContext);
	const { nuevoCargo } = useContext(CargoContext);
	const navigate = useNavigate();
	const ref = React.createRef();

	///////////////////////////////////////////
	// STATE
	const {
		factura,
		//apertura,
		//cierre,
		mesa,
		rcargos,
		roomservice,
		habitacion,
		status,
		impresa,
		importe,
		pago,
		propina,
	} = cheque;

	const [, setUsr] = useState(null);
	const { id } = useParams();
	const [date, setDate] = useState(new Date());
	const [impDesc, setImpDesc] = useState(0);
	const [disabled, setDisabled] = useState(false);
	const [enaHab, setenaHab] = useState(true);
	const [descuento, setDescuento] = useState(0);
	const [cambio, setCambio] = useState(0);
	const [mp, setMp] = useState("00");
	const [hab, setHab] = useState("0");
	const [facturable, setFacturable] = useState(false);
	const [gtotal, setGtotal] = useState(0);

	let tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds

	var options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	let [hour, minutes] = [
		(date.getHours() < 10 ? "0" : "") + date.getHours(),
		(date.getMinutes() < 10 ? "0" : "") + date.getMinutes(),
	];

	/////////////////////////////////////////////
	//METODOS
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	//////////////////////////////////////////////
	//AGREGAR CARGO A HABITACION
	const submitCargo = async () => {
		const importe = impDesc / 1.16;
		const iva = importe * 0.16;

		let cajero = prompt("Ingrese su clave de usuario");
		if (cajero === null) {
			setAlert("Operación cancelada", "danger");
			throw new Error("No ingresó la clave de usuario");
		}
		setUsr(cajero);
		let res = await traerCuentaByHab(hab);

		if (
			hab === 0 ||
			res.data.cierre !== "0000-00-00 00:00:00" ||
			parseFloat(res.data.credito) <= 0
		) {
			setAlert("La habitación no tiene Autorizado Cargos", "danger");
			setLoading(false);
			throw new Error("No ingresó la clave de usuario");
		}
		if (res.success) {
			const cargo = {
				habitacion: hab,
				cuenta_id: res.data.cuenta,
				concepto: "Consumo",
				metodo_pago: "Cargo a Habitación",
				importe: importe,
				iva: iva,
				ieh: 0,
				referencia: "Cheque: " + cheque.id,
				comentario: "propina:" + parseFloat(propina).toFixed(2),
				cajero: cajero,
			};
			const resp = await nuevoCargo(cargo);
			console.log(resp.data);
			if (resp.success) {
				setAlert("Cuenta transferida", "success");
				return true;
			}
		} else return setAlert("Error en la transferencia", "danger");
	};

	//////////////////////////////////////////////
	//CAMBIO DE STATUS DE CUENTA
	const statusCuenta = async () => {
		//let obj = cheque;
		let obj = { id: cheque.id };
		setLoading(true);
		if (cheque.status === "abierta") {
			obj = {
				...obj,
				cierre: new Date(Date.now() - tzoffset)
					.toISOString()
					.slice(0, 19)
					.replace("T", " "),
				status: "cerrada",
				impresa: mp,
				descuento: descuento,
				importe: impDesc,
				habitacion: hab,
			};
			console.log(impDesc);

			if (parseFloat(impDesc).toFixed(4) > parseFloat(pago)) {
				setLoading(false);
				return setAlert("La cuenta no está balanceada", "danger");
			}
			if (mp === "05") {
				//traeCuenta();
				if (hab !== "0" && hab !== null && hab !== "")
					if (await submitCargo()) obj = { ...obj, status: "transferida" };
					else {
						setLoading(false);
						return setAlert("Falló transferencia", "danger");
					}
			}
			if (parseInt(impDesc) === 0 || parseInt(cheque.importe) === 0) {
				const usr = prompt(
					"Esta por eliminar el cargo, esta accion no se puede deshacer. ingrse su clave para confirmar"
				);
				if (usr !== null) {
					obj = { ...obj, status: "cancelada", usuario: usr };
				} else {
					setLoading(false);
					setAlert("operacion cancelada", "danger");
					return;
				}
			}
		} else {
			obj = {
				...obj,
				cierre: null,
				status: "abierta",
				//habitacion:0
			};
		}

		await actualizaCheque(obj);
		traerCheque(id);
		if (obj.status === "abierta") setDisabled(false);
		else setDisabled(true);
	};

	//////////////////////////////
	//FUNCION QUE INICIA PROCESO DE FACTURACION
	const facturar = async () => {
		navigate(`/cfacturacion/${id}`);
	};
	////////////////////////////////////
	//SELECTOR DE METODO DE PAGO
	const selector = (e) => {
		if (e.target.value === "05") setenaHab(false);
		else setenaHab(true);
		if (e.target.value === "06") calcDescuento({ target: { value: 50 } });
		if (e.target.value === "07") calcDescuento({ target: { value: 20 } });
		setMp(e.target.value);
	};
	////////////////////////////////
	//CALCULO DESCUENTO
	const calcDescuento = async (e) => {
		const sub = importe * ((100 - e.target.value) / 100);
		setDescuento(e.target.value);
		setImpDesc(sub);
		calcTotal();
	};

	////////////////////////////////
	//CALCULA TOTAL Y CAMBIO
	const calcTotal = () => {
		let cam = 0,
			granTotal = 0;

		if (impDesc > 0) {
			granTotal = parseFloat(impDesc) + parseFloat(propina);
		} else {
			granTotal = parseFloat(importe) + parseFloat(propina);
		}
		setGtotal(granTotal);
		if (rcargos !== "Cheque sin cargos" && typeof rcargos !== "undefined") {
			rcargos.forEach((row) => {
				if (row.clave === 502) {
					cam = parseFloat(pago) - parseFloat(granTotal);
					if (parseFloat(pago) >= parseFloat(granTotal)) {
						setCambio(cam);
					} else setCambio(0);
				}
			});
		}
	};

	////////////////////////////////
	//onChange Habitación
	const onChangeHab = (e) => {
		setHab(e.target.value);
	};

	useEffect(() => {
		if (typeof cheque.id === "undefined") traerCheque(id);
		setHab(habitacion);
		if (habitacion !== 0 || impresa === 5 || roomservice === 1) {
			setenaHab(true);
			setHab(habitacion);
		}

		if (status === "abierta" && descuento > 0 && descuento < 100) {
			let descRecalc = importe * ((100 - descuento) / 100);
			setImpDesc(descRecalc);
			calcTotal();
		} else {
			setImpDesc(importe);
			calcTotal();
		}

		if (status === "cerrada") setFacturable(true);
		else setFacturable(false);

		if (status !== "abierta") setDisabled(true);
		else setDisabled(false);

		setInterval(() => setDate(new Date()), 30000);
	}, [cheque, descuento, impDesc, cargos]);

	return (
		<div className='xmodalOver'>
			<h1 className=''>
				<p>Cheque número: {id} </p>

				{date.toLocaleDateString("es-ES", options)}
				<p className='floatRight'> {hour + ":" + minutes}hrs </p>
			</h1>
			<div>
				<hr />

				<h2 className='grid-5'>
					<div>
						<p>Mesa: {mesa}</p>
					</div>
					<div>
						<p className={roomservice ? "" : "noprint"}>
							Room Service:{roomservice ? "Si" : "No"}
						</p>
					</div>
					<div>
						<p className={roomservice || mp === 5 ? "" : "noprint"}>
							Habitación: {habitacion}
						</p>
					</div>
					<div>
						<p className=''>Status: {status}</p>
					</div>
					<div>
						<p className='noprint'>Factura: {factura}</p>
					</div>
				</h2>
				<hr />
				<NuevoRcargo />
				{loading && <Spinner />}
			</div>
			<br />
			<table className='w-100'>
				<thead>
					<tr>
						<th className='noprint' width='7%'>
							Id
						</th>
						<th width='10%'>Cantidad</th>
						<th className='' width='10%'>
							Clave
						</th>
						<th width='20%'>Descripción</th>
						<th width='10%'>Precio</th>
						<th width='10%'>Importe</th>
						<th width='10%'>Fecha</th>
						<th width='10%'>Hora</th>
						<th className='' width='5%'>
							Cajero
						</th>
						<th className='noprint'>Cancelar</th>
					</tr>
				</thead>
				<tbody>
					{typeof cheque.rcargos === "object" ? (
						cheque.rcargos.map((res, index) => (
							<Rcargostd key={index} idc={index} cargo={res} />
						))
					) : (
						<tr>
							<td>{cheque.rcargos}</td>
						</tr>
					)}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan='2' className='noprint' />
						<th scope='row'>Consumo:</th>
						<td>{moneyForm(impDesc)}</td>
						<th>Propina:</th>
						<td>{moneyForm(propina)}</td>
						<th>Pagos:</th>
						<td>{moneyForm(pago)}</td>
						<th>Total: </th>
						<td>
							<strong className='medium'>{moneyForm(gtotal)}</strong>
						</td>
					</tr>
					<tr>
						<th colSpan='2'>
							<select
								value={mp}
								onChange={selector}
								disabled={disabled}
								required
								className='noprint'
							>
								<option value='00'>- Pago Especial -</option>
								<option value='05'>Cargo a Habitacion</option>
								<option value='06'>Cargo a Empleado</option>
								<option value='07'>Cortesía</option>
							</select>
						</th>
						<th colSpan='1' className='noprint'>
							<input
								type='text'
								value={hab === "0" ? "" : hab}
								disabled={enaHab}
								placeholder='Habitación'
								onChange={onChangeHab}
							/>
						</th>
						<th colSpan='1' className='noprint'>
							<div style={{ display: "none" }}>
								<Cheque hab={hab} mp={mp} cheque={cheque} ref={ref} />
							</div>
							<ReactToPrint
								trigger={() => <button className='btn btn-primary'>Imprimir</button>}
								content={() => ref.current}
							/>
						</th>
						<th>
							<button
								className='btn-primary btn noprint'
								onClick={statusCuenta}
								disabled={status === "abierta" || status === "cerrada" ? false : true}
							>
								{status === "abierta"
									? "Cerrar Cuenta"
									: status === "cerrada"
									? "Reabrir Cuenta"
									: status}
							</button>
						</th>
						<th>
							<button
								className={
									!facturable
										? "btn-block btn-danger noprint"
										: "btn-block btn-success noprint"
								}
								disabled={!facturable}
								onClick={facturar}
							>
								Facturar
							</button>
						</th>
						<th colSpan='2' className='noprint'>
							<p>DESCUENTO: {descuento}%</p>
							<input
								type='text'
								disabled={disabled}
								value={descuento}
								onChange={calcDescuento}
							/>
						</th>
						<th colSpan='2'>
							<p className='medium'>CAMBIO: ${cambio}</p>
						</th>
					</tr>
				</tfoot>
			</table>
			<span colSpan='2' className='floatRight fas'></span>
			<br />
			<hr />
		</div>
	);
};

export default Rcargosth;
