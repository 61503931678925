import React from "react";

const Reportetd = ({
	cuenta: {
		id,
		fechafactura,
		apertura,
		cierre,
		roomservice,
		habitacion,
		status,
		importe,
		pago,
		rcargos,
		descuento,
		propina,
		mesa,
		turno,
		impresa,
	},
}) => {
	///////////////////////
	let total = parseFloat(importe) + parseFloat(propina);
	////////////////////////////////
	//METODOS
	const convertirTimestamp = (timestamp) => {
		if (timestamp) {
			timestamp = timestamp.split(" ");
			const hora = timestamp[1].slice(0, 5);
			timestamp = timestamp[0].split("-");
			const meses = [
				"dud",
				"Enero",
				"Feb",
				"Mar",
				"Abril",
				"Mayo",
				"Jun",
				"Jul",
				"Ago",
				"Sep",
				"Oct",
				"Nov",
				"Dic",
			];
			const fecha =
				timestamp[2] +
				"/" +
				meses[timestamp[1].replace(/^0+/, "")] +
				"/" +
				timestamp[0].substring(2, 4);
			return [hora, fecha];
		} else return [null, null];
	};

	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	const [hora, fecha] = convertirTimestamp(cierre);

	const abonos = (forma) => {
		let efe = 0,
			tdeb = 0,
			tcred = 0;
		if (Object.values(rcargos).length > 0 && rcargos !== "Cheque sin cargos") {
			rcargos.forEach((row, i) => {
				if (row.status === 1)
					switch (row.clave) {
						case 502:
							efe += parseFloat(row.importe);
							break;
						case 503:
							tcred += parseFloat(row.importe);
							break;
						case 504:
							tdeb += parseFloat(row.importe);
							break;
						default:
							break;
					}
				if (parseFloat(efe) > parseFloat(importe) + parseFloat(propina))
					efe = importe;
			});
			switch (forma) {
				case "502":
					return efe;
				case "503":
					return tcred;
				case "504":
					return tdeb;
				default:
					break;
			}
		}
	};

	return (
		<tr className={status === "cancelada" ? "text-line text-italic" : ""}>
			<td>{id}</td>
			<td>{fecha}</td>
			<td>{hora}</td>
			<td>{roomservice ? habitacion : mesa}</td>
			<td>{moneyForm(importe / 1.16)}</td>
			<td>{moneyForm(propina)}</td>
			<td>{moneyForm((importe / 1.16) * 0.16)}</td>
			<td>{moneyForm(total)}</td>
			<td>{moneyForm(abonos("502"))}</td>
			{/*<td>{moneyForm(abonos("504"))}</td>*/}
			<td>{moneyForm(abonos("503") + abonos("504"))}</td>
			<td>{impresa === 5 ? moneyForm(importe + propina) : moneyForm(0)}</td>
			<td>{impresa === 6 ? moneyForm(importe) : moneyForm(0)}</td>
			<td>
				{impresa !== 6 && descuento > 0 && descuento < 50
					? moneyForm(importe)
					: moneyForm(0)}
			</td>
		</tr>
	);
};
export default Reportetd;
