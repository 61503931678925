import React, {useContext, useState} from "react";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import alertContext from "../../contexto/alerta/alertContext";


const NuevoCheque = () => {
    ///////////////////////////////////////////
    //CONTEXTO
    const cafeteriaContest = useContext(CafeteriaContext);
    const {nuevoCheque, loading, setLoading, traerChequesFecha} = cafeteriaContest;
    const {setAlert} = useContext(alertContext);

    ////////////////////////////////////////////
    //CAMPO GENERICO
    const useField = ({type}) => {
        const [value, setValue] = useState('');
        const [checked, setChecked] = useState(false);

        const onChange = event => {
            setValue(event.target.value);
            if(type=="checkbox")
                setChecked(event.target.checked);
        }
        return{
            type, value, checked, onChange
        }
    }

    ////////////////////////////////////////////
    // DECLARACION DE CAMPOS
    const mesa = useField({type:"number"});
    const room = useField({type:"checkbox"});
    const habitacion = useField({type:"text"});

    //////////////////////////////////////////////
    //Métodos
    const submitCheque = (event) => { 
        event.preventDefault();
        const rs = room.checked?1:0
        setLoading(true);
        nuevoCheque(JSON.stringify({
                    mesa: parseInt(mesa.value),
                    roomservice: rs,
                    habitacion: parseInt(habitacion.value)
                }));
        console.log(rs)
        setAlert("Nuevo Cheque Abierto","success")
    }

    return <>
        <form onSubmit={submitCheque} className='grid-6'>
                <div>
                    <label>R. SERVICE</label><br/>
                    <input {...room} className="w-100"/>
                </div>
                <div>
                    <label>HABITACION</label>
                    <input {...habitacion} disabled={room.checked ? false : true}/>
                </div>
                <div>
                    <label>N° MESA</label>
                    <input {...mesa} disabled={room.checked ? true:false}/>
                </div>
                <div>
                    
                    <button type="submit" className="btn-primary">Nuevo Cheque</button>
                </div>
        </form>
    </>
}

export default NuevoCheque;