const tarifas = [
	{
		IdTarifa: "1",
		Nombre: "S10D1",
		Importe: "810",
	},
	{
		IdTarifa: "3",
		Nombre: "S15D1",
		Importe: "765",
	},
	{
		IdTarifa: "5",
		Nombre: "S20D1",
		Importe: "600",
	},
	{
		IdTarifa: "7",
		Nombre: "S25D1",
		Importe: "562.5",
	},
	{
		IdTarifa: "9",
		Nombre: "SGLD1",
		Importe: "900",
	},
	{
		IdTarifa: "10",
		Nombre: "DBLD2",
		Importe: "940",
	},
	{
		IdTarifa: "11",
		Nombre: "TPLD3",
		Importe: "980",
	},
	{
		IdTarifa: "12",
		Nombre: "CPLD4",
		Importe: "1020",
	},
	{
		IdTarifa: "13",
		Nombre: "S30D1",
		Importe: "675",
	},
	{
		IdTarifa: "14",
		Nombre: "S50D1",
		Importe: "375",
	},
	{
		IdTarifa: "15",
		Nombre: "D10D2",
		Importe: "846",
	},
	{
		IdTarifa: "16",
		Nombre: "D15D2",
		Importe: "799",
	},
	{
		IdTarifa: "17",
		Nombre: "D20D2",
		Importe: "632",
	},
	{
		IdTarifa: "18",
		Nombre: "D25D2",
		Importe: "592.5",
	},
	{
		IdTarifa: "19",
		Nombre: "D30D2",
		Importe: "512",
	},
	{
		IdTarifa: "20",
		Nombre: "D50D2",
		Importe: "355",
	},
	{
		IdTarifa: "21",
		Nombre: "T10D3",
		Importe: "882",
	},
	{
		IdTarifa: "22",
		Nombre: "T15D3",
		Importe: "833",
	},
	{
		IdTarifa: "23",
		Nombre: "T20D3",
		Importe: "664",
	},
	{
		IdTarifa: "24",
		Nombre: "T25D3",
		Importe: "622.5",
	},
	{
		IdTarifa: "25",
		Nombre: "T30D3",
		Importe: "830",
	},
	{
		IdTarifa: "26",
		Nombre: "T50D3",
		Importe: "624",
	},
	{
		IdTarifa: "27",
		Nombre: "C10D4",
		Importe: "918",
	},
	{
		IdTarifa: "28",
		Nombre: "C15D4",
		Importe: "867",
	},
	{
		IdTarifa: "29",
		Nombre: "C20D4",
		Importe: "696",
	},
	{
		IdTarifa: "30",
		Nombre: "C25D4",
		Importe: "652.5",
	},
	{
		IdTarifa: "31",
		Nombre: "C30D4",
		Importe: "569",
	},
	{
		IdTarifa: "32",
		Nombre: "C50D4",
		Importe: "397",
	},
	{
		IdTarifa: "36",
		Nombre: "NOCD1",
		Importe: "0",
	},
	{
		IdTarifa: "38",
		Nombre: "ZZZD1",
		Importe: "40",
	},
	{
		IdTarifa: "39",
		Nombre: "ZZZD2",
		Importe: "80",
	},
	{
		IdTarifa: "40",
		Nombre: "ZZZD3",
		Importe: "120",
	},
	{
		IdTarifa: "41",
		Nombre: "Z10D1",
		Importe: "36",
	},
	{
		IdTarifa: "42",
		Nombre: "Z10D2",
		Importe: "72",
	},
	{
		IdTarifa: "43",
		Nombre: "Z10D3",
		Importe: "108",
	},
	{
		IdTarifa: "44",
		Nombre: "Z20D1",
		Importe: "32",
	},
	{
		IdTarifa: "45",
		Nombre: "Z20D2",
		Importe: "64",
	},
	{
		IdTarifa: "46",
		Nombre: "Z20D3",
		Importe: "96",
	},
	{
		IdTarifa: "47",
		Nombre: "Z25D1",
		Importe: "30",
	},
	{
		IdTarifa: "48",
		Nombre: "Z25D2",
		Importe: "60",
	},
	{
		IdTarifa: "49",
		Nombre: "Z25D3",
		Importe: "90",
	},
	{
		IdTarifa: "51",
		Nombre: "Z15D2",
		Importe: "68",
	},
	{
		IdTarifa: "52",
		Nombre: "Z15D3",
		Importe: "102",
	},
	{
		IdTarifa: "53",
		Nombre: "Z50D1",
		Importe: "20",
	},
	{
		IdTarifa: "54",
		Nombre: "Z50D2",
		Importe: "40",
	},
	{
		IdTarifa: "55",
		Nombre: "Z50D3",
		Importe: "60",
	},
	{
		IdTarifa: "56",
		Nombre: "Z15D1",
		Importe: "34",
	},
	{
		IdTarifa: "69",
		Nombre: "Z30D1",
		Importe: "28",
	},
	{
		IdTarifa: "70",
		Nombre: "SPRO1",
		Importe: "855",
	},
	{
		IdTarifa: "71",
		Nombre: "DPRO2",
		Importe: "893",
	},
	{
		IdTarifa: "72",
		Nombre: "TPRO3",
		Importe: "931",
	},
	{
		IdTarifa: "73",
		Nombre: "CPRO4",
		Importe: "969",
	},
	{
		IdTarifa: "74",
		Nombre: "DESD1",
		Importe: "559",
	},
	{
		IdTarifa: "75",
		Nombre: "DESD2",
		Importe: "591",
	},
	{
		IdTarifa: "76",
		Nombre: "DESD3",
		Importe: "623",
	},
	{
		IdTarifa: "77",
		Nombre: "DESD4",
		Importe: "655",
	},
	{
		IdTarifa: "78",
		Nombre: "Z15D4",
		Importe: "136",
	},
	{
		IdTarifa: "79",
		Nombre: "BDYD1",
		Importe: "720",
	},
	{
		IdTarifa: "80",
		Nombre: "BDYD2",
		Importe: "752",
	},
	{
		IdTarifa: "81",
		Nombre: "BDYD3",
		Importe: "784",
	},
	{
		IdTarifa: "82",
		Nombre: "BDYD4",
		Importe: "816",
	},
	{
		IdTarifa: "83",
		Nombre: "EXPD1",
		Importe: "688",
	},
	{
		IdTarifa: "84",
		Nombre: "EXPD2",
		Importe: "688",
	},
	{
		IdTarifa: "85",
		Nombre: "EXPD3",
		Importe: "720",
	},
	{
		IdTarifa: "86",
		Nombre: "EXPD4",
		Importe: "752",
	},
	{
		IdTarifa: "87",
		Nombre: "ZDESD",
		Importe: "536",
	},
	{
		IdTarifa: "88",
		Nombre: "PRTD1",
		Importe: "712.5",
	},
	{
		IdTarifa: "89",
		Nombre: "PRTD2",
		Importe: "750.5",
	},
	{
		IdTarifa: "90",
		Nombre: "PRTD3",
		Importe: "788.5",
	},
	{
		IdTarifa: "91",
		Nombre: "PRTD4",
		Importe: "826.5",
	},
	{
		IdTarifa: "92",
		Nombre: "UASD1",
		Importe: "720",
	},
	{
		IdTarifa: "93",
		Nombre: "UASD2",
		Importe: "752",
	},
	{
		IdTarifa: "94",
		Nombre: "UASD3",
		Importe: "784",
	},
	{
		IdTarifa: "95",
		Nombre: "UASD4",
		Importe: "816",
	},
	{
		IdTarifa: "96",
		Nombre: "ZPRD1",
		Importe: "38",
	},
	{
		IdTarifa: "97",
		Nombre: "ZPRD2",
		Importe: "76",
	},
	{
		IdTarifa: "98",
		Nombre: "ZPRD3",
		Importe: "114",
	},
	{
		IdTarifa: "99",
		Nombre: "ZUAD1",
		Importe: "30",
	},
	{
		IdTarifa: "100",
		Nombre: "ZUAD2",
		Importe: "60",
	},
	{
		IdTarifa: "101",
		Nombre: "ZUAD3",
		Importe: "90",
	},
	{
		IdTarifa: "102",
		Nombre: "FDJ01",
		Importe: "1600",
	},
	{
		IdTarifa: "103",
		Nombre: "FDJD1",
		Importe: "1600",
	},
	{
		IdTarifa: "104",
		Nombre: "FDJD4",
		Importe: "1800",
	},
	{
		IdTarifa: "105",
		Nombre: "FVSD2",
		Importe: "2500",
	},
	{
		IdTarifa: "106",
		Nombre: "FVSD4",
		Importe: "2900",
	},
	{
		IdTarifa: "107",
		Nombre: "FDJD2",
		Importe: "1600",
	},
	{
		IdTarifa: "108",
		Nombre: "ZZD15",
		Importe: "1000",
	},
	{
		IdTarifa: "109",
		Nombre: "FEXD2",
		Importe: "1440",
	},
	{
		IdTarifa: "110",
		Nombre: "FEXD4",
		Importe: "1600",
	},
	{
		IdTarifa: "111",
		Nombre: "ZZZD4",
		Importe: "160",
	},
	{
		IdTarifa: "112",
		Nombre: "UAAD1",
		Importe: "688",
	},
	{
		IdTarifa: "113",
		Nombre: "UAAD2",
		Importe: "688",
	},
	{
		IdTarifa: "114",
		Nombre: "UAAD3",
		Importe: "720",
	},
	{
		IdTarifa: "115",
		Nombre: "UAAD4",
		Importe: "752",
	},
	{
		IdTarifa: "116",
		Nombre: "FERD2",
		Importe: "1500",
	},
	{
		IdTarifa: "117",
		Nombre: "FERD4",
		Importe: "1800",
	},
	{
		IdTarifa: "118",
		Nombre: "BDFD2",
		Importe: "688",
	},
	{
		IdTarifa: "119",
		Nombre: "BDFD3",
		Importe: "1600",
	},
];
export default tarifas;
