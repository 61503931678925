import React, { useContext, useEffect, useState } from "react";
import RegistroContext from "../../contexto/registro/registroContext.js";
import CargosRAtd from "./CargosRAtd";
import Spinner from "../elementos/Spinner.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const CargosRAth = () => {
	const registroContext = useContext(RegistroContext);
	const { rentasa, traerCargosAuditoria, aplicarCargosAuditoria } =
		registroContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const nonoc = rentasa.filter((row) => row.comentario !== "NOCD1");
	const [sndd, setSndd] = useState(false);
	let tz = new Date(Date.now()).getTimezoneOffset();
	const hoy = new Date(Date.now() - tz);
	console.log(hoy);
	let current = hoy.getDate();
	hoy.setDate(current - 1);

	let mes = hoy.getMonth() + 1;
	let dia = hoy.getDate();
	let ano = hoy.getFullYear();

	let fechaCargo = dia + "/" + mes + "/" + ano;
	//////////////////////////////
	// aplicarCargos
	const aplicarCargos = async () => {
		const usr = prompt("Está por cargar las rentas");
		if (usr === null) {
			return;
		}
		const resp = await aplicarCargosAuditoria(usr);
		if (resp.success) {
			console.log(resp);
			setAlert("Cargos realizados con éxito", "success");
			setSndd(false);
		} else {
			setAlert(resp.messages, "danger");
		}
	};

	//////////////////////////////////
	//check o traer cargos
	const checkoTraer = async () => {
		const resp = await traerCargosAuditoria();
		console.log(resp);
		if (resp.success) {
			setAlert("registros encontrados para cargo automatico", "success");
			setSndd(true);
		} else {
			setAlert(resp.messages, "danger");
		}
	};
	///////////////////////////////
	//use effect
	useEffect(() => {
		if (rentasa.length === 0) {
			checkoTraer();
		}
	}, [rentasa]);
	return (
		<>
			<h2>Cargo Automático de rentas del dia: {fechaCargo}</h2>
			<table className='w-100 printabla'>
				<thead className='stickiehead'>
					<tr>
						<th>Hab</th>
						<th>Concepto</th>
						<th>Importe</th>
						<th>IVA</th>
						<th>IEH</th>
						<th>Total</th>
						<th>Coment.</th>
						<th>Ref.</th>
					</tr>
				</thead>
				<tbody>
					{nonoc.length === 0 ? (
						<tr className='w-100'>
							<td colSpan={8}>
								<Spinner />
							</td>
						</tr>
					) : (
						nonoc.map((row, i) => {
							return <CargosRAtd key={i} cargo={row} />;
						})
					)}
				</tbody>
			</table>
			{sndd && (
				<button className='btn btn-dark floatRight my-1' onClick={aplicarCargos}>
					Aplicar Cargos
				</button>
			)}
		</>
	);
};

export default CargosRAth;
