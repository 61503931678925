import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AutContext from "../../contexto/autentificacion/autContext.js";

const PrivateRoutes = () => {
	const autContext = useContext(AutContext);
	const { logstatus, refreshSession } = autContext;
	refreshSession();
	///autentifica aca
	let loged = true;
	return loged ? <Outlet /> : <Navigate to='/login' />;
};

export default PrivateRoutes;
