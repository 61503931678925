import React, { useContext, useEffect, useState } from "react";
import Spinner from "../elementos/Spinner";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import Cuentastd from "./Cuentastd";

const Cuentasth = () => {
	////////////////////
	//contexto
	const cuentaContext = useContext(CuentaContext);
	const { traerCuentasAct, cuenta, cuentas } = cuentaContext;

	//let cuentasLength = Object.keys(cuentas).length;
	console.log(cuentas);
	useEffect(() => {
		if (cuentas.length === 0) {
			traerCuentasAct();
		}
	}, []);
	console.log(cuentas);
	if (cuentas.length === 0) return <Spinner />;

	return (
		<>
			<div className=''>
				<table id='car' className='w-100 printabla'>
					<thead className='stickiehead'>
						<tr>
							<th>Cta.</th>
							<th style={{ width: "20%" }}>Huesped</th>
							<th style={{ width: "15%" }}>Empresa</th>
							<th>Grupo</th>
							<th>Habs.</th>
							<th>Tarifa</th>
							<th>Llegada</th>
							<th>Salida</th>
							<th>Saldo</th>
							<th>Reg</th>
							<th style={{ width: "15%" }}>Oserva.</th>
						</tr>
					</thead>
					<tbody>
						{cuentas.map((res) => (
							<Cuentastd key={res.folio} cuenta={res} />
						))}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Cuentasth;
