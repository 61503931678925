import React, { useContext, useState, useEffect } from "react";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import Reportetd from "./Reportetd";
import Spinner from "../elementos/Spinnermodal";

const Reporteth = () => {
	////////////////////////////
	//CONTEXT
	const { cheques, loading, setVistaReporte } = useContext(CafeteriaContext);

	////////////////////////////
	//STATE
	const [fechaR, setFechaR] = useState("");
	const [imp, setImp] = useState(0);
	const [emp, setEmp] = useState(0);
	const [cort, setCort] = useState(0);
	const [efect, setEfect] = useState(0);
	const [propina, setPropina] = useState(0);
	const [tDeb, setTDeb] = useState(0);
	const [tCred, setTCred] = useState(0);
	const [cHab, setCHab] = useState(0);
	const [cerradas, setCerradas] = useState([]);
	const [turno, setTurno] = useState("1");

	////////////////////////////
	//METODOS
	const cambioVista = () => {
		setVistaReporte(false);
	};

	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	const fechaBusq = () => {
		let options = {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
		};
		let date = new Date();
		let fecha = "";
		if (Object.keys(cheques).length === 0) {
			setFechaR("NO HAY MOVIMIENTOS");
			return;
		}

		if (Object.keys(cerradas).length !== 0) {
			fecha = cerradas[0].cierre.split(" ");
			fecha = fecha[0].split("-");
			date.setFullYear(fecha[0]);
			date.setMonth(fecha[1] - 1);
			date.setDate(fecha[2]);
		}
		setFechaR(date.toLocaleDateString("es-ES", options));
	};
	const convertirTimestamp = (timestamp) => {
		if (timestamp) {
			timestamp = timestamp.split(" ");
			const time = timestamp[1].split(":");
			const hora = time[0];
			const minutos = time[1];
			return [hora, minutos];
		} else return [null, null];
	};
	const imprimir = () => {
		let areaImpresion = document.getElementById("tabla").innerHTML;
		let original = document.body.innerHTML;

		document.body.innerHTML = areaImpresion;
		window.print();
		document.body.innerHTML = original;
		window.location.reload();
	};
	////////////////////////////
	//FILTRA CUENTAS CERRADAS/FACTURADAS/TRANSFERIDAS
	const reporteInicial = () => {
		let efe = 0,
			tdeb = 0,
			tcred = 0,
			chab = 0,
			prop = 0,
			imp = 0,
			emp = 0,
			cort = 0;
		let cerrada = [];
		fechaBusq();
		cheques.forEach((res) => {
			//if(res.status!=="abierta"&&res.status!=="cancelada"){
			let hora;
			if (res.status !== "abierta") {
				if (res.status === "cancelada") [hora] = convertirTimestamp(res.apertura);
				else [hora] = convertirTimestamp(res.cierre);
				if (turno === "1" && hora >= 7 && hora < 15) {
					cerrada = [...cerrada, res];
				} else if (turno === "2" && hora >= 15 && hora <= 23)
					cerrada = [...cerrada, res];
			}
		});
		if (cerrada.length > 0) {
			cerrada.forEach((res) => {
				if (res.status !== "cancelada") {
					res.rcargos.forEach((row, i) => {
						if (row.status !== 0)
							switch (row.clave) {
								case 502:
									efe += parseFloat(row.importe);
									break;
								case 503:
									tcred += parseFloat(row.importe);
									break;
								case 504:
									tdeb += parseFloat(row.importe);
									break;
								default:
									break;
							}
					});
					switch (res.impresa) {
						case 5:
							chab += parseFloat(res.importe);
							break;
						case 6:
							emp += parseFloat(res.importe);
							break;
						case 7:
							cort += parseFloat(res.importe);
							break;
						default:
							break;
					}
					prop += parseFloat(res.propina);
					imp += parseFloat(res.importe);
					//efe = imp;
				}
			});
		}
		setImp(imp);
		setCort(cort);
		setEmp(emp);
		setCerradas(cerrada);
		setEfect(efe);
		setPropina(prop);
		setTDeb(tdeb);
		setTCred(tcred);
		setCHab(chab);
	};

	useEffect(() => {
		reporteInicial();
	}, [cheques, turno]);

	return (
		<>
			<div className='xmodalOver' id='tabla'>
				<h1>
					<div className='grid-6 noprint'>
						<select
							value={turno}
							onChange={(e) => setTurno(e.target.value)}
							className='noprint'
						>
							<option value={1}>Turno 1</option>
							<option value={2}>Turno 2</option>
						</select>
					</div>
					Corte del día {fechaR}, Turno {turno}
				</h1>
				<div>{loading && <Spinner />}</div>
				<table className='w-100'>
					<thead>
						<tr>
							<th>Cheque</th>
							<th>Fecha</th>
							<th>Hora</th>
							<th>Mesa</th>
							<th>Importe</th>
							<th>Propina</th>
							<th>IVA</th>
							<th>Total</th>
							<th>Efectivo</th>
							{/*<th>T. Debito</th>*/}
							<th>Pago con Tarjeta</th>
							<th>Cargo Habit</th>
							<th>Empleado</th>
							<th>Cortesía</th>
						</tr>
					</thead>
					<tbody>
						{cerradas.map((res) => (
							<Reportetd key={res.id} cuenta={res} />
						))}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan='3' />
							<th>Totales</th>
							<th>{moneyForm(imp / 1.16)}</th>
							<th>{moneyForm(propina)}</th>
							<th>{moneyForm((imp / 1.16) * 0.16)}</th>
							<th>{moneyForm(imp + propina)}</th>
							<th>{moneyForm(efect)}</th>
							{/*<th>{moneyForm(tDeb)}</th>*/}
							<th>{moneyForm(tCred + tDeb)}</th>
							<th>{moneyForm(cHab)}</th>
							<th>{moneyForm(emp)}</th>
							<th>{moneyForm(cort)}</th>
						</tr>
					</tfoot>
				</table>
			</div>
			<button className='btn noprint' onClick={cambioVista}>
				---VISTA CUENTAS
			</button>
			<button className='btn noprint' onClick={imprimir}>
				IMPRIMIR REPORTE
			</button>
		</>
	);
};

export default Reporteth;
