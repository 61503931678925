import React, { useState, useContext, useEffect } from "react";
import Relog from "../elementos/Relog";
import CargoContext from "../../contexto/cargo/cargoContext.js";
import Cargosthlight from "../cargos/Cargosthlight.js";
import AlertContext from "../../contexto/alerta/alertContext";
import Abonosthlight from "../cargos/Abonosthlight.js";
import Reportefacturath from "../facturas/Reportefacturath";
import Busquedarep from "../cargos/Busquedarep";
import Repofactmesth from "../facturas/Repofactmesth";

const Corte = () => {
	const cargoContext = useContext(CargoContext);
	const alertContext = useContext(AlertContext);
	const { cerrarTurno } = cargoContext;
	const { setAlert } = alertContext;
	const [turno, setTurno] = useState({});
	const [tab, setTab] = useState("caja");
	const hoyrw = new Date();
	const hoyr = new Date(hoyrw.getTime() - hoyrw.getTimezoneOffset() * 60000);
	const hoy = hoyr.toISOString().split("T")[0];
	const meses = [
		"dud",
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	let phoy = hoy.slice(0, 10).split("-");
	const [fechab, setFechab] = useState(
		phoy[2] + " de " + meses[parseInt(phoy[1])] + " de " + phoy[0]
	);

	///////////////////////////////
	//cerrarTurnooC
	const cerrarTurnoOnC = async () => {
		const usr = prompt("Al cerrar el turno se resetea el reporte está seguro?");
		if (usr === null) {
			return;
		}
		const resp = await cerrarTurno(usr);
		if (resp.success) {
			setAlert("el turno ha sido cerrado", "success");
		} else {
			setAlert(resp.data.messages[0], "danger");
		}
	};

	//////////////////////////////
	// tab control
	const tabControl = (e) => {
		setTab(e.target.value);
	};

	/////////////////////////////
	// fecha control
	const fechaControl = (fecha) => {
		let fechaf = fecha.split("-");
		let fechax =
			fechaf[2] + " de " + meses[parseInt(fechaf[1])] + " de " + fechaf[0];
		setFechab(fechax);
	};

	useEffect(() => {}, [fechab]);
	return (
		<div>
			<header className='grid-3 noprint'>
				<h1>Corte</h1>
				<button className='my-1 btn btn-dark' onClick={cerrarTurnoOnC}>
					Cerrar Turno
				</button>
				<div className='text-right'>
					<Relog />
				</div>
				<div className='w-100 text-center'>
					<button onClick={cerrarTurnoOnC}></button>
				</div>
			</header>

			<section className=''>
				<Busquedarep fechaData={fechaControl} />
			</section>
			<section>
				<button className='btn btn-dark noprint' onClick={tabControl} value='caja'>
					Corte de Caja
				</button>
				<button
					className='btn btn-dark noprint'
					onClick={tabControl}
					value='transacciones'
				>
					Transacciones
				</button>
				<button
					className='btn btn-dark noprint'
					onClick={tabControl}
					value='facturas'
				>
					Facturas
				</button>
			</section>
			<section className='tabs'>
				{tab === "caja" && <Abonosthlight fecha={fechab} />}
				{tab === "transacciones" && <Cargosthlight fecha={fechab} />}
				{tab === "facturas" && <Reportefacturath fecha={fechab} />}
			</section>
		</div>
	);
};

export default Corte;
