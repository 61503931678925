import React, { useContext, useState, useEffect } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import CuentaContext from "../../contexto/cuenta/cuentaContext.js";
import RegistroContext from "../../contexto/registro/registroContext.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import tarifas from "../../assets/tarifas";
import Rfcpicker from "../facturacion/Rfcpicker.js";

const RegistroDirecto = ({ togg }) => {
	const hoyrw = new Date();
	const hoyr = new Date(hoyrw.getTime() - hoyrw.getTimezoneOffset() * 60000);
	const hoy = hoyr.toISOString().split("T")[0];
	////////////////////
	//contexto
	const reservaContext = useContext(ReservaContext);
	const { nuevaReserva, nuevoCliente, traerTarifas } = reservaContext;
	const facturacionContext = useContext(FacturacionContext);
	const { empresa } = facturacionContext;
	const cuentaContext = useContext(CuentaContext);
	const { crearCuenta } = cuentaContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const registroContext = useContext(RegistroContext);
	const {
		crearRegistro,
		unSetRegistros,
		habitaciones,
		traerVaciasL,
		patchHabitacion,
	} = registroContext;
	const tarsolo = tarifas.map((r) => {
		return r.Nombre;
	});
	const habsolo = habitaciones.map((row) => {
		if (row.status === "vaciaL" || row.status === "asignadaL") {
			return parseInt(row.habitacion);
		}
	});

	///////////////////
	//state
	const [nombre, setNombre] = useState("");
	const [apellido, setApellido] = useState("");
	const [grupo, setGrupo] = useState("");
	const [llegada, setLlegada] = useState(hoy);
	const [hora, setHora] = useState("11:30");
	const [salida, setSalida] = useState("");
	const [tarifa, setTarifa] = useState("sencilla");
	const [personas, setPersonas] = useState(1);
	const [sHabitaciones, setsHabitaciones] = useState(1);
	const [info, setInfo] = useState("");
	const [ref, setRef] = useState("");
	const [factura, setFactura] = useState(false); // tooggle clientes
	const [cliente_id, setCliente_id] = useState(null);
	const [empresa_id, setEmpresa_id] = useState(null);
	const [credito, setCred] = useState(0);
	const [usr, setUsr] = useState(null);
	const [empresaCheck, setEmpresaCheck] = useState(null);

	const [habsArr, setHabsArr] = useState([{ habitacion: 0, tarifa: "NOCD1" }]);
	///////////////////
	//onChange inputs
	const onChangeNombre = (e) => setNombre(e.target.value);
	const onChangeApellido = (e) => setApellido(e.target.value);
	const onChangeGrupo = (e) => setGrupo(e.target.value);
	const onChangeLlegada = (e) => setLlegada(e.target.value);
	const onChangeSalida = (e) => setSalida(e.target.value);
	const onChangeTarifa = (e) => setTarifa(e.target.value);
	const onChangePersonas = (e) => setPersonas(e.target.value);
	const onChangeInfo = (e) => setInfo(e.target.value);
	const onChangeRef = (e) => setRef(e.target.value);
	const onChangeCred = (e) => setCred(e.target.value);
	const onChangeFactura = (e) =>
		e.target.checked ? setFactura(true) : setFactura(false); // toogle clienteszzz

	///////////////////////
	// metodo para crear el body del cliente
	const getClienteData = () => {
		const clienteObj = {};
		clienteObj.nombre = nombre;
		clienteObj.apellido = apellido;
		grupo.length === 0 ? (clienteObj.grupo = null) : (clienteObj.grupo = grupo);
		return clienteObj;
	};

	///////////////////////
	// metodo para crear el body de la reserva
	const getReservaData = () => {
		const reservaObj = {};
		reservaObj.llegada = llegada;
		reservaObj.hora = hora;
		reservaObj.salida = salida;
		reservaObj.tarifa = tarifa;
		reservaObj.personas = personas;
		reservaObj.sHabitaciones = sHabitaciones;
		reservaObj.grupo = grupo;
		info.length > 0 && (reservaObj.info = info);
		ref.length > 0 && (reservaObj.ref = ref);
		reservaObj.cliente_id = cliente_id;
		reservaObj.empresa_id = empresa_id;
		reservaObj.reservo = usr;
		setUsr(null);
		return reservaObj;
	};

	////////////////////////
	//metodo para crear el cuerpo de la cuenta
	const getCuentaData = () => {
		const cuentaObj = {};
		cuentaObj.cliente_id = "";
		if (factura) {
			cuentaObj.empresa_id = "";
		}
		cuentaObj.reserva_id = "";
		if (credito > 0) {
			cuentaObj.credito = credito;
		}
		return cuentaObj;
	};

	////////////////////////////////////
	// contructor del body de registros
	const getRegistroData = () => {
		const registroObj = { registros: [] };
		for (let i = 0; i < sHabitaciones; i++) {
			let row = {
				cliente_id: 0,
				reserva_id: 0,
				cuenta_id: 0,
				empresa_id: null,
				titular: nombre,
				habitacion: habsArr[i].habitacion,
				tarifa: habsArr[i].tarifa,
				registro: "",
			};
			registroObj.registros.push(row);
		}
		return registroObj;
	};

	////////////////////////////
	// métodos
	const submitRregistro = async (ev) => {
		ev.preventDefault();
		traerVaciasL();
		let usr = await prompt("Ingrese su clave de usuario");
		if (usr === null) {
			setAlert("opercacion cancelada", "danger");
			return;
		}
		habsArr.forEach((row) => {
			let check = habsolo.includes(parseInt(row.habitacion));
			let check2 = tarsolo.includes(row.tarifa);
			if (!check) {
				setAlert(
					"la habitacion " + row.habitacion + " no esta disponible, verifique",
					"danger"
				);
				throw new Error("fallo");
			}
			if (!check2) {
				setAlert(
					"la Tarifa " + row.tarifa + " tiene un error, verifique",
					"danger"
				);
				throw new Error("fallo");
			}
		});
		const clienteData = getClienteData();
		const reservaData = getReservaData();
		const cuentaData = getCuentaData();
		const registroData = getRegistroData();
		cuentaData.aperturo = usr;
		reservaData.reservo = usr;
		registroData.registro = usr;

		const clienteResp = await nuevoCliente(clienteData);
		if (factura && empresaCheck) {
			setEmpresa_id(empresa.id);
			reservaData.empresa_id = empresa.id;
			cuentaData.empresa_id = empresa.id;
			registroData.empresa_id = empresa.id;
		}
		if (clienteResp.success) {
			setCliente_id(clienteResp.data.id);
			reservaData.cliente_id = clienteResp.data.id;
			cuentaData.cliente_id = clienteResp.data.id;
			for (let i = 0; i < registroData.registros.length; i++) {
				registroData.registros[i].cliente_id = clienteResp.data.id;
				registroData.registros[i].registro = usr;
			}
		} else {
			setAlert(clienteResp.messages[0], "danger");
			return;
		}
		const reservaResp = await nuevaReserva(reservaData);

		if (reservaResp.success) {
			cuentaData.reserva_id = reservaResp.data.id;
			for (let i = 0; i < registroData.registros.length; i++) {
				registroData.registros[i].reserva_id = reservaResp.data.id;
			}
		} else {
			setAlert(reservaResp.messages, "danger");
		}

		const cuentaResp = await crearCuenta(cuentaData);
		if (cuentaResp.success) {
			for (let i = 0; i < registroData.registros.length; i++) {
				registroData.registros[i].cuenta_id = cuentaResp.data.id;
			}
		} else {
			setAlert(cuentaResp.messages[0], "danger");
		}
		const registroResp = await crearRegistro(registroData);
		if (registroResp.success) {
			setAlert("los regístros se crearon existosamente", "success");
		} else {
			setAlert(registroResp.messages[0], "danger");
		}
		unSetRegistros();
		togg();
		//setUsr(usr);
	};

	///////////////////
	// habs
	const habsPlus = (e) => {
		e.preventDefault();
		if (sHabitaciones < 100) {
			setsHabitaciones(sHabitaciones + 1);
			setHabsArr([...habsArr, { habitacion: 0, tarifa: "NOCD1" }]);
		}
	};
	const habsMinus = (e) => {
		e.preventDefault();
		if (sHabitaciones > 1) {
			setsHabitaciones(sHabitaciones - 1);
			habsArr.splice(-1);
		}
	};
	////////////
	//onchange
	const onChangeM = (index, e) => {
		const values = [...habsArr];
		values[index][e.target.name] = e.target.value;
		setHabsArr(values);
	};

	///////////
	//Tarifa
	const onChangeTar = (index, e) => {
		const values = habsArr;
		values[index].tarifa = e.target.value;
		setHabsArr(values);
		console.log("values", values);
	};

	//////////////////
	//useEffect
	useEffect(() => {
		if (tarifas.length === 0) {
			traerTarifas();
		}
		if (habitaciones.length === 0) traerVaciasL();
		//si submiteas usr se vuelve true
		if (sHabitaciones > 0) {
			let habsArrL = habsArr.length;
			if (habsArrL < sHabitaciones) {
			}
		}
		if (Object.keys(empresa).length !== 0) {
			setEmpresaCheck(true);
		}
		//eslint-disable-next-line
	}, [usr, tarifas, sHabitaciones, empresa]);

	////////////////////////////////////////////////////////////////////////////
	//return salida return salida return salida
	return (
		<div id='nuevaReserva'>
			<form action='' onSubmit={submitRregistro} className='card'>
				<section className='grid-5'>
					{/*Columna Uno*/}
					<article className='columna gspan-3'>
						{/*Campo nombre*/}
						<label htmlFor='nombre'>Nombre</label>
						<input
							name='nombre'
							type='text'
							value={nombre}
							onChange={onChangeNombre}
							className=''
							required
						/>
						{/*Campo apellido*/}
						<label htmlFor='apellido'>Apellido</label>
						<input
							name='apellido'
							type='text'
							value={apellido}
							onChange={onChangeApellido}
							className=''
							required
						/>
						<label htmlFor='grupo'>Grupo</label>
						<input
							name='grupo'
							type='text'
							value={grupo}
							onChange={onChangeGrupo}
							className=''
						/>
					</article>

					{/*Columna Dos*/}
					<article className='columna'>
						{/*Campo llegada*/}
						<label htmlFor='llegada'>Llegada</label>
						<input
							name='llegada'
							type='date'
							value={llegada}
							onChange={onChangeLlegada}
							min={hoy}
							max='2122-01-01'
							className=''
							required
						/>
						{/*Campo salida*/}
						<label htmlFor='salida'>Salida</label>
						<input
							name='salida'
							type='date'
							value={salida}
							onChange={onChangeSalida}
							className=''
							min={hoy}
							max={"2123-01-01"}
							required
						/>
					</article>
					{/*Columna tress*/}
					<article className='columna'>
						<label htmlFor='credito'>Credito</label>
						<input
							name='credito'
							type='number'
							value={credito}
							onChange={onChangeCred}
							className=''
						/>
						{/*Campo personas*/}
						<label htmlFor='personas'>No de personas</label>
						<input
							type='number'
							name='personas'
							value={personas}
							onChange={onChangePersonas}
							min='1'
							max='300'
						/>
						{/*Campo sHabitaciones*/}
						<label htmlFor='sHabitaciones'>Habitaciones</label>
						<div className='grid-3'>
							<h2>{sHabitaciones}</h2>
							<button onClick={habsPlus}>+</button>
							<button onClick={habsMinus}>-</button>
						</div>
					</article>
				</section>
				<section id='habsec' className='grid-5 gspan-6 my-1'>
					{habsArr.map((row, i) => {
						return (
							<div key={i} id={`hab-${i}`} className=''>
								<p className='small bold'>Habitacion</p>
								<div
									className='grid-2 w-100'
									style={{
										border: "1px solid black",
										padding: "4px",
										gridGap: "0.5rem",
										borderRadius: "6px",
									}}
								>
									<input
										type='text'
										name='habitacion'
										list='hab'
										value={row.noHab}
										placeholder='Numero'
										size={11}
										onChange={(e) => onChangeM(i, e)}
										required
									/>
									<datalist id='hab'>
										{habitaciones.map((res, i) => {
											let n = i + 1;
											<option>----</option>;
											if (habitaciones[n - 1].habitacion % 100 === 32)
												return (
													<option key={i} value={res.habitacion}>
														{res.habitacion}-
													</option>
												);
											else
												return (
													<option key={i} value={res.habitacion}>
														{res.habitacion}-
													</option>
												);
										})}
									</datalist>
									<input
										type='text'
										name='tarifa'
										list='tarifas'
										placeholder='Tarifa'
										onChange={(e) => onChangeTar(i, e)}
										size={3}
										required
									/>
									<datalist id='tarifas'>
										{tarifas.map((res, i) => {
											return (
												<option key={i} value={res.Nombre}>
													{res.Nombre}
												</option>
											);
										})}
									</datalist>
								</div>
							</div>
						);
					})}
				</section>
				<section className='my-1'>
					<article>
						<input
							type='checkbox'
							value='1'
							name='factura'
							onChange={onChangeFactura}
						/>
						<label htmlFor='factura' className='m-1'>
							Asignar empresa{" "}
							{empresaCheck !== null && <i className='fas fa-check-circle paloma'></i>}
						</label>
					</article>
				</section>
				{factura ? <Rfcpicker /> : null}
				<section className='grid-2'>
					<div>
						<label htmlFor='info'>Comentarios</label>
						<textarea
							name='info'
							id=''
							rows='3'
							value={info}
							onChange={onChangeInfo}
						></textarea>
					</div>
					<div>
						<label htmlFor='ref'>Referencia</label>
						<textarea
							name='ref'
							id=''
							rows='3'
							value={ref}
							onChange={onChangeRef}
						></textarea>
					</div>
					<input
						type='submit'
						className='btn btn-dark btn-block my gspan-2'
						value='Crear Reservacion'
					/>
				</section>
			</form>
		</div>
	);
};

export default RegistroDirecto;
