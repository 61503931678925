import React, { useContext } from "react";
import Cargosth from "../cargos/Cargosth";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import Cuentasth from "../../componentes/cuentas/Cuentasth";
import BusquedaCuenta from "../cuentas/BusquedaCuenta";
const Cuentas = () => {
	const cuentaContext = useContext(CuentaContext);
	const { modal, cuenta } = cuentaContext;
	return (
		<>
			<h1>Cuentas</h1>
			<BusquedaCuenta />
			<Cuentasth />
			{modal && <Cargosth id={cuenta.id} />}
		</>
	);
};

export default Cuentas;
