import React, { useContext, useState, useEffect } from "react";
import CuentaContext from "../../contexto/cuenta/cuentaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const BusquedaCuenta = () => {
	//////////////////////////////
	// Contextos
	const cuentaContext = useContext(CuentaContext);
	const {
		traerCuentaByHabs,
		traerCuentaByNom,
		traerCuentasAct,
		traerCuentasCer,
		traerCuentasFact,
		traerCuentasCan,
		traerCuentaByEmpresa,
		traerCuentaByLlegada,
		traerCuentaByDia,
		traerCuentaByGrupo,
		traerCuentaByFolio,
	} = cuentaContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const [head, setHead] = useState("Abiertas a: ");
	const [facturadas, setFacturadas] = useState(false);

	/////////////////////////////////////
	// formateador de fecha
	const ffrm = (fecha) => {
		let infRw = true;
		let inf = "";
		if (typeof fecha === "undefined") {
			infRw = new Date();
			inf = infRw.toLocaleDateString("es-MX", {
				year: "numeric",
				month: "long",
				day: "2-digit",
			});
		} else {
			let fechArr = fecha.split("-");
			console.log(fechArr[1]);
			infRw = new Date(fechArr[0], fechArr[1] - 1);
			inf = infRw.toLocaleDateString("es-MX", {
				year: "numeric",
				month: "long",
			});
		}
		return inf;
	};
	let tz = new Date(Date.now()).getTimezoneOffset() * 60000;
	const hoy = new Date(Date.now() - tz).toISOString().slice(0, 7);
	const [fact, setFact] = useState(hoy);
	//////////////////////////////
	// States
	const [habitacion, setHabitacion] = useState("");
	const [nombre, setNombre] = useState("");
	const [tipo, setTipo] = useState("hab");
	const [empresa, setEmpresa] = useState("");
	const [llegada, setLlegada] = useState("");
	const [salida, setSalida] = useState("");
	const [grupo, setGrupo] = useState("");
	const [folio, setFolio] = useState("");

	/////////////////////////////
	// onChanges
	const onChangeHab = (e) => setHabitacion(e.target.value);
	const onChangeNom = (e) => setNombre(e.target.value);
	const onChangeEmpresa = (e) => setEmpresa(e.target.value);
	const onChangeLlegada = (e) => setLlegada(e.target.value);
	const onChangeSalida = (e) => setSalida(e.target.value);
	const onChangeGrupo = (e) => setGrupo(e.target.value);
	const onChangeFolio = (e) => setFolio(e.target.value);
	const onDateChange = (e) => {
		setFact(e.target.value);
		traerCuentasFact(e.target.value);
	};

	/////////////////////////////
	//métodos
	const onSubHab = async (e) => {
		e.preventDefault();
		const resp = await traerCuentaByHabs(habitacion);
		resp.success
			? setAlert(`Se encontró la siguiente cuenta`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
	};

	/////////////////////////////
	//métodos
	const onSubNom = async (e) => {
		e.preventDefault();
		const resp = await traerCuentaByNom(nombre);
		resp.success
			? setAlert(`Se encontró la siguiente cuenta`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
	};

	/////////////////////////////
	//métodos
	const onSubEmpresa = async (e) => {
		e.preventDefault();
		const resp = await traerCuentaByEmpresa(empresa);
		resp.success
			? setAlert(`Se encontró la siguiente cuenta`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
	};

	/////////////////////////////
	//métodos
	const onSubLlegada = async (e) => {
		e.preventDefault();
		const resp = await traerCuentaByLlegada(llegada);
		resp.success
			? setAlert(`Se encontró la siguiente cuenta`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
	};

	/////////////////////////////
	//métodos
	const onSubSalida = async (e) => {
		e.preventDefault();
		const resp = await traerCuentaByDia(salida);
		resp.success
			? setAlert(`Se encontró la siguiente cuenta`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
	};

	/////////////////////////////
	//métodos
	const onSubGrupo = async (e) => {
		e.preventDefault();
		const resp = await traerCuentaByGrupo(grupo);
		resp.success
			? setAlert(`Se encontró la siguiente cuenta`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
	};

	/////////////////////////////
	//métodos
	const onSubFolio = async (e) => {
		e.preventDefault();
		const resp = await traerCuentaByFolio(folio);
		resp.success
			? setAlert(`Se encontró la siguiente cuenta`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
	};

	/////////////////////////////
	//traerAbiertas
	const traerAbiertas = async (e) => {
		e.preventDefault();
		const resp = await traerCuentasAct();
		resp.success
			? setAlert(`Se encontraron la siguientes cuentas`, "success")
			: setAlert(`No se encontraron cuentas con Abiertas`, "danger");
		resp.success && setHead("Abiertas a: ");
		setFacturadas(false);
	};

	/////////////////////////////
	//traerAbiertas
	const traerFacturadas = async (e) => {
		e.preventDefault();
		const resp = await traerCuentasFact();
		if (resp.success) {
			setAlert(`Se encontraron la siguientes cuentas`, "success");
			setFacturadas(true);
		} else {
			setAlert(`No se encontraron cuentas Facturadas`, "danger");
		}
	};

	/////////////////////////////
	//traerAbiertas
	const traerCerradas = async (e) => {
		e.preventDefault();
		const resp = await traerCuentasCer();
		resp.success
			? setAlert(`Se encontraron la siguientes cuentas`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
		resp.success && setHead("Cerradas a: ");
		setFacturadas(false);
	};

	/////////////////////////////
	//traerAbiertas
	const traerCanceladas = async (e) => {
		e.preventDefault();
		const resp = await traerCuentasCan();
		resp.success
			? setAlert(`Se encontraron la siguientes cuentas`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
		resp.success && setHead("Canceladas a: ");
		setFacturadas(false);
	};

	useEffect(() => {}, [tipo]);
	return (
		<>
			<section className='grid-5 noprint'>
				<article>
					<label htmlFor='tipo'>
						<p className='medium'>Selecciones el Tipo de busqueda</p>
					</label>
					<select name='tipo' id='' onChange={(e) => setTipo(e.target.value)}>
						<option value='hab'>Habitacion</option>
						<option value='nom'>Apellido</option>
						<option value='empresa'>Empresa</option>
						<option value='llegada'>llegada</option>
						<option value='salida'>Salida</option>
						<option value='grupo'>Grupo</option>
						<option value='folio'>Reserva</option>
					</select>
				</article>
				<article className='gspan-2'>
					{tipo === "nom" ? (
						<form onSubmit={onSubNom} className='gspan-2 '>
							<input
								type='text'
								name='nombre'
								value={nombre}
								onChange={onChangeNom}
								className='my-03'
							/>
							<input
								type='submit'
								className='btn btn-dark btn-block my-03'
								value='Buscar'
							/>
						</form>
					) : tipo === "hab" ? (
						<form onSubmit={onSubHab} className=''>
							<input
								type='text'
								name='habitacion'
								value={habitacion}
								onChange={onChangeHab}
							/>
							<input
								type='submit'
								className='btn btn-dark btn-block my'
								value='Buscar'
							/>
						</form>
					) : tipo === "empresa" ? (
						<form onSubmit={onSubEmpresa} className=''>
							<input
								type='text'
								name='empresa'
								value={empresa}
								onChange={onChangeEmpresa}
							/>
							<input
								type='submit'
								className='btn btn-dark btn-block my'
								value='Buscar'
							/>
						</form>
					) : tipo === "llegada" ? (
						<form onSubmit={onSubLlegada} className=''>
							<input
								type='date'
								name='llegada'
								value={llegada}
								onChange={onChangeLlegada}
							/>
							<input
								type='submit'
								className='btn btn-dark btn-block my-03'
								value='Buscar'
							/>
						</form>
					) : tipo === "salida" ? (
						<form onSubmit={onSubSalida} className=''>
							<input
								type='date'
								name='salida'
								value={salida}
								onChange={onChangeSalida}
							/>
							<input
								type='submit'
								className='btn btn-dark btn-block my-03'
								value='Buscar'
							/>
						</form>
					) : tipo === "grupo" ? (
						<form onSubmit={onSubGrupo} className=''>
							<input type='text' name='grupo' value={grupo} onChange={onChangeGrupo} />
							<input
								type='submit'
								className='btn btn-dark btn-block my-03'
								value='Buscar'
							/>
						</form>
					) : tipo === "folio" ? (
						<form onSubmit={onSubFolio} className=''>
							<input
								type='number'
								name='folio'
								value={folio}
								onChange={onChangeFolio}
							/>
							<input
								type='submit'
								className='btn btn-dark btn-block my-03'
								value='Buscar'
							/>
						</form>
					) : null}
				</article>
				<div>
					<button className='btn btn-dark btn-block my-03' onClick={traerAbiertas}>
						Abiertas
					</button>
					<button className='btn btn-dark btn-block my-03' onClick={traerCerradas}>
						Cerradas
					</button>
				</div>
				<div>
					<button className='btn btn-dark btn-block my-03' onClick={traerFacturadas}>
						Facturadas
					</button>
					<button className='btn btn-dark btn-block my-03' onClick={traerCanceladas}>
						Canceladas
					</button>
				</div>
			</section>
			<section className='grid-2 text-middle'>
				<h3>{head + ffrm(fact)}</h3>
				{facturadas && (
					<form className='no-print text-right'>
						<div>
							<label htmlFor='mes'>Buscar por mes</label>
							<input
								className='datemes'
								name='mes'
								type='month'
								value={fact}
								onChange={onDateChange}
							/>
						</div>
					</form>
				)}
			</section>
		</>
	);
};

export default BusquedaCuenta;
