import React, { useState, useEffect, useContext } from "react";
import Cafeteriatd from "./Cafeteriatd";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import formato from "../../assets/FacturaGlobalCafe33";
import facturacionContext from "../../contexto/facturacion/facturacionContext";
import alertContext from "../../contexto/alerta/alertContext";
import Spinner from "../elementos/Spinnermodal";

const Cafeteriath = (props) => {
	////////////////////////////////////////
	// CONTEXTO
	const cafeteriaContext = useContext(CafeteriaContext);
	const {
		cheque,
		cheques,
		traerChequesFecha,
		setVistaReporte,
		setVistaFacturas,
		vistaFacturas,
	} = cafeteriaContext;
	const {
		traerFolioB,
		timbrarFactura,
		loading,
		setLoading,
		traerFolio,
		//folio,
		//traerFacturasByFechas,
	} = useContext(facturacionContext);
	const { setAlert } = useContext(alertContext);
	///////////////////////////////////////
	// STATE
	let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

	const [date, setDate] = useState(
		new Date(Date.now() - tzoffset).toISOString().slice(0, 19)
	);
	const [importe, setImporte] = useState(0);
	const [propina, setPropina] = useState(0);
	const [pago, setPago] = useState(0);
	const [listaF, setListaF] = useState([]);
	const [status, setStatus] = useState("abierta");
	const [boton, setBoton] = useState([
		{ id: "abierta", className: "btn btn-primary" },
		{ id: "cerrada", className: "btn noprint" },
		{ id: "facturada", className: "btn noprint" },
		{ id: "transferida", className: "btn noprint" },
		{ id: "cancelada", className: "btn noprint" },
	]);
	///////////////////////////////////////
	//METODOS
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	/////////////////////////////////////////
	//ACTUALIZA LISTA POR STATUS Y TOTALES
	const lista = async (event) => {
		let botones = boton;
		let prop = 0,
			imp = 0,
			pag = 0;
		let cheq = [];
		cheques.forEach((row) => {
			if (row.status === event.target.id) cheq = [...cheq, row];
		});
		botones.forEach((res) => {
			if (event.target.id === res.id) res.className = "btn btn-primary";
			else res.className = "btn noprint";
		});
		cheq.forEach((res) => {
			imp += parseInt(res.importe);
			prop += parseInt(res.propina);
			pag += parseInt(res.pago);
		});
		setListaF(cheq);
		setBoton(botones);
		setStatus(event.target.id);
		setImporte(parseInt(imp));
		setPago(parseInt(pag));
		setPropina(parseInt(prop));
	};

	////////////////////////////////
	//Vista Reportes
	const reporteTurno = () => {
		setVistaReporte(true);
		traerChequesFecha(props.fecha);
	};

	////////////////////////////////
	//Vista Reportes
	const reporteFacturas = () => {
		setVistaReporte(false);
		setVistaFacturas(true);
		//traerFacturasByFechas(props.fecha,props.fecha);
	};

	////////////////////////////////
	//FACTURA GLOBAL
	const facturacionG = async () => {
		let efe = 0,
			tcred = 0,
			tdeb = 0;
		let cerradas = [];
		let fpago = "",
			fp = "",
			fecha = "",
			comentario = "";
		let importe = 0,
			subtotal = 0,
			iva = 0;
		if (Object.keys(listaF).length === 0) {
			setAlert("No hay cheques que facturar", "danger");
			return;
		}
		fecha = listaF[0].cierre.split(" ");
		fecha = fecha[0].split("-");
		const folio = await traerFolioB();
		setLoading(true);
		listaF.forEach((res) => {
			cerradas.push(res.id);
			comentario +=
				"Folio: " +
				res.id.toString() +
				" Total: $" +
				parseFloat(res.importe).toFixed(2).toString() +
				" | ";
			console.log(parseFloat(res.importe), importe);
			importe += parseFloat(res.importe);
			res.rcargos.forEach((car) => {
				if (car.clave === 503) tcred += parseFloat(car.importe);
				if (car.clave === 504) tdeb += parseFloat(car.importe);
				if (car.clave === 502) efe = importe - tcred - tdeb; //efe += parseFloat(res.importe);
			});
		});

		if (efe > tcred && efe > tdeb) {
			fpago = "01";
			fp = "Efectivo";
		} else if (tcred > efe && tcred > tdeb) {
			fpago = "04";
			fp = "T. Crédito";
		} else {
			fpago = "28";
			fp = "T. Débito";
		}
		importe = importe;

		subtotal = parseFloat(importe) / 1.16;
		iva = Math.round(subtotal * 16) / 100;
		subtotal = importe - iva;
		console.log("efe tc td", efe, tcred, tdeb, importe, comentario);
		let usr = await prompt(
			"Está por timbrar la factura global del día, ingrese su contraseña"
		);

		///////LLENADO DE FORMATO
		formato.Comprobante.Fecha = date;
		formato.Comprobante.Folio = folio.folio;
		formato.Comprobante.FormaPago = fpago;
		formato.Comprobante.Total = importe;
		formato.Comprobante.SubTotal = subtotal;
		formato.Comprobante.InformacionGlobal.Meses = fecha[1];
		formato.Comprobante.InformacionGlobal.Año = fecha[0];
		formato.Comprobante.Conceptos[0].NoIdentificacion =
			fecha[0] + fecha[1] + fecha[2];
		formato.Comprobante.Conceptos[0].ValorUnitario = subtotal;
		formato.Comprobante.Conceptos[0].Importe = subtotal;
		formato.Comprobante.Conceptos[0].Impuestos.Traslados[0].Base = subtotal;
		formato.Comprobante.Conceptos[0].Impuestos.Traslados[0].Importe = iva;
		formato.Comprobante.Impuestos.TotalImpuestosTrasladados = iva;
		formato.Comprobante.Impuestos.Traslados = [
			{
				Base: subtotal,
				Impuesto: "002",
				TipoFactor: "Tasa",
				TasaOCuota: "0.160000",
				Importe: iva,
			},
		];
		formato.CamposPDF = {
			tipoComprobante: "FACTURA",
			comentarios: comentario,
		};
		const factura = {
			body: formato,
			//mail:"info@realplaza.com.mx",
			mail: "info@realplazaags.com.mx",
			usr: usr,
			cheque_id: cerradas,
			pdf: {
				nombre: null,
				rsocial: formato.Comprobante.Receptor.Nombre,
				rfc: formato.Comprobante.Receptor.Rfc,
				regimen: "",
				direccion: "",
				cp: "20000",
				entrada: "",
				salida: listaF[0].cierre,
				fpago: fp,
				uso: fp !== 99 ? "03 Gastos en General" : "P01 Por Definir",
				ciudad: "AGUASCALIENTES",
				estado: "AGUASCALIENTES",
				pais: "MÉXICO",
				mpago: "PUE",
				conceptos: [
					{
						fecha: listaF[0].cierre,
						cantidad: "1",
						clave: "01010101",
						concepto: "Venta",
						importe: subtotal,
						iva: iva,
						ieh: 0,
					},
				],
				hab: "",
				recibe: usr,
				cerro: usr,
				hospedaje: "0",
				cafeteria: moneyForm(subtotal),
				bar: "0",
				lavanderia: "0",
				cnegocios: "0",
				propinas: "",
				iva: moneyForm(iva),
				ieh: "0",
				subtotal: moneyForm(subtotal),
				total: moneyForm(importe),
				comentarios: comentario,
			},
		};
		console.log(factura);

		let respuesta = await timbrarFactura(factura);
		if (respuesta.success) {
			window.location.reload();
			setAlert(respuesta.messages, "success");
		} else setAlert(respuesta.data.messages[0], "danger");
		setLoading(false);
	};

	const calculo = () => {
		let imp = 0,
			prop = 0,
			pag = 0;
		let cheq = [];
		if (
			Object.keys(cheques).length > 0 &&
			cheques !== [] &&
			cheques[0].id !== 0
		) {
			cheques.forEach((row) => {
				if (row.status === status) cheq = [...cheq, row];
			});
			cheq.forEach((res) => {
				imp += parseInt(res.importe);
				prop += parseInt(res.propina);
				pag += parseInt(res.pago);
			});
		}
		if ((listaF.length === 0 && cheq.length !== 0) || listaF !== cheq) {
			setListaF(cheq);
		}
		setImporte(parseInt(imp));
		setPago(parseInt(pag));
		setPropina(parseInt(prop));
	};

	useEffect(() => {
		if (
			props.fecha !== "" &&
			(Object.keys(cheques).length === 0 || cheques[0].id === 0)
		) {
			traerChequesFecha(props.fecha);
			traerFolio("B");
		}
		calculo();
	}, [status, cheque, cheques, props.fecha, vistaFacturas]);

	return (
		<>
			<button onClick={lista} {...boton[0]}>
				ACTIVAS
			</button>
			<button onClick={lista} {...boton[1]}>
				CERRADAS
			</button>
			<button onClick={lista} {...boton[2]}>
				TIMBRADAS
			</button>
			<button onClick={lista} {...boton[3]}>
				TRANSFERIDAS
			</button>
			<button onClick={lista} {...boton[4]}>
				CANCELADAS
			</button>
			{loading && <Spinner />}
			<table className='w-100'>
				<thead>
					<tr>
						{/*						<th>Factura</th>	*/}
						<th>Cheque</th>
						<th>Fecha</th>
						<th>Hora</th>
						<th>Mesa</th>
						<th>Room S.</th>
						<th>Habitacion</th>
						<th>Importe</th>
						<th>Pago</th>
						<th>Propina</th>
						<th>Forma Pago</th>
					</tr>
				</thead>
				<tbody>
					{listaF.map((res) => (
						<Cafeteriatd key={res.id} cuenta={res} />
					))}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan='5'></td>
						<th>Totales</th>
						<th>{moneyForm(importe)}</th>
						<th>{moneyForm(importe)}</th>
						<th>{moneyForm(propina)}</th>
					</tr>
				</tfoot>
			</table>
			<button className='btn noprint' onClick={reporteTurno}>
				VISTA REPORTE---
			</button>
			{status === "cerrada" ? (
				<button onClick={facturacionG} className='btn btn-primary noprint'>
					Factura Global del Día
				</button>
			) : null}
			{status === "facturada" ? (
				<button onClick={reporteFacturas} className='btn btn-primary noprint'>
					Reporte de Facturas
				</button>
			) : (
				<br />
			)}
		</>
	);
};

export default Cafeteriath;
